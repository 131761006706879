import { truncate } from 'lodash';
import { IPromotedToken } from '../../interfaces/promotedToken';
import { compactSmall, numberAsDigitNotation } from '../../utils';
import googleAnalytics from '../../utils/bubblesGa';
import { promotedTokenImageURL } from '../../utils/promotedTokenUtils';
import PriceChange from '../PriceChange/PriceChange';
import './styles.scss';

interface IProps {
  token: IPromotedToken
}

const TrendingToken = ({ token }: IProps) => {
  const goToLink = () => {
    googleAnalytics.event('ad_click', {
      url: token?.url,
      event_source: 'ad_trending_token'
    });
    window.open(token?.url, '_blank');
  };

  const imageURL = promotedTokenImageURL(token);

  return (
    <div className="trending-token" onClick={goToLink}>
      <div className="trending-token--upper">
        <div className='trending-token--image'>
          <img
              src={imageURL}
              height={24}
              width={24}
              alt="logo"
              className="coin-icon"
            />
        </div>
        <div className='trending-token--name'>{truncate(token.name, { length: 100 })}</div>
      </div>
      <div className="trending-token--lower">
        <div className='trending-token--price'>{token.token?.priceUsd ? `$${numberAsDigitNotation(compactSmall(token.token?.priceUsd))}` : '-'}</div>
        <div className='trending-token--percentage'>
          <PriceChange value={token.token?.priceChangePercentage.h24} />
        </div>
      </div>
    </div>
  );
};

export default TrendingToken;
