import { StonApiClient } from '@ston-fi/api';
import { useCallback } from 'react';

import { TON_REFERRAL_ADDRESS } from '../constants';
import { ISimulateSwapResponse } from '../interfaces/stonFi';

const stonApiClient = new StonApiClient();

const useSimulateSwap = () => {
  const simulateSwap = useCallback(
    async (offerAddress: string, askAddress: string, offerUnits: string, slippageTolerance: string = '0.01'): Promise<ISimulateSwapResponse> => {
      return await stonApiClient.simulateSwap({
        askAddress: askAddress,
        offerAddress: offerAddress,
        offerUnits: offerUnits,
        slippageTolerance: slippageTolerance,
        referralAddress: TON_REFERRAL_ADDRESS
    });
  }, [])

  const simulateReverseSwap = useCallback(
    async (offerAddress: string, askAddress: string, askUnits: string, slippageTolerance: string = '0.01'): Promise<ISimulateSwapResponse> => {
      return await stonApiClient.simulateReverseSwap({
        askAddress: askAddress,
        offerAddress: offerAddress,
        askUnits: askUnits,
        slippageTolerance: slippageTolerance,
        referralAddress: TON_REFERRAL_ADDRESS
    });
  }, [])

  return { simulateSwap, simulateReverseSwap }
};

export default useSimulateSwap;