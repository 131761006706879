import LanguageIcon from '@mui/icons-material/Language';
import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';
import { useContext } from 'react';
import { IToken } from '../../interfaces/token';
import { AppStoreContext } from '../../store';
import CopyButton from '../CopyButton/CopyButton';
import Information from '../Information/Information';

interface IProps {
  token: IToken;
}

const usdOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};

const TokenDescription = ({ token }: IProps) => {
  const store = useContext(AppStoreContext);

  if (!token) return null;

  return (
    <Box className="token-description">
      <Box className="token-info-label">Token</Box>
      <Box sx={{ marginTop: '8px' }}>
        <ul className="descripton-list">
          <li className="token-item">
            <span>Created</span>
            <span className="value">
              {DateTime.fromJSDate(new Date(token?.poolCreatedAt)).toFormat(
                'dd MMMM yy, HH:mm'
              )}
            </span>
          </li>

          <li className="token-item">
            <span>Market Cap</span>
            <span className="value">
              {token?.marketCapUsd
                ? Number(token.marketCapUsd).toLocaleString('en-US', usdOptions)
                : ''}
            </span>
          </li>

          <li className="token-item">
            <span>Liquidity</span>
            <span className="value">
              {token?.liquidity
                ? Number(token.liquidity).toLocaleString('en-US', usdOptions)
                : ''}
            </span>
          </li>

          <li className="token-item">
            <span>Total Supply</span>
            <span className="value">
              {token?.totalSupply ? `${token.totalSupply} ${token.symbol}` : ''}
            </span>
          </li>

          <li className="token-item">
            <span>
              Volume
              <Information value="Total volume of token transactions (last 24H)" />
            </span>
            <span className="value">
              {token?.volumeUsd?.h24
                ? Number(token.volumeUsd?.h24).toLocaleString(
                    'en-US',
                    usdOptions
                  )
                : ''}
            </span>
          </li>

          <li className="token-item">
            <span>
              TXNs
              <Information value="Number of transactions (last 24H)" />
            </span>
            <span className="value">
              {token?.transactionsCount ? token.transactionsCount : ''}
            </span>
          </li>

          <li className="token-item">
            <span>
              Holders
              <Information value="Holders" />
            </span>
            <span className="value">
              {token.holders}
            </span>
          </li>

          <li className="token-item">
            <span>
              Contract
              <Information value="Contract address." />
            </span>
            <span className="value">
              {token.quoteTokenAddress.substr(0, 5) +
                '...' +
                token.quoteTokenAddress.substr(
                  token.quoteTokenAddress.length - 5
                )}
              <CopyButton value={token.quoteTokenAddress} />
            </span>
          </li>
        </ul>
      </Box>
      <Box sx={{ marginTop: '16px' }} className="token-info-label">
        Additional info
      </Box>
      <Box className="">
        <ul className="descripton-list">
          <li className="token-item">
            <span>Links</span>
            <span className="value"></span>
          </li>
        </ul>
      </Box>
      <Box className="socials" sx={{ display: 'flex', marginTop: '8px' }}>
        {!!token.tokenSocials?.websites && (
          <a
            href={token.tokenSocials?.websites[0]}
            target="_blank"
            rel="noreferrer"
            className="social-link">
            <LanguageIcon />
            Website
          </a>
        )}
        {!!token.tokenSocials?.telegramHandle && (
          <a
            href={`https://t.me/${token.tokenSocials.telegramHandle}`}
            target="_blank"
            rel="noreferrer"
            className="social-link">
            <TelegramIcon />
            Telegram
          </a>
        )}
        {!!token.tokenSocials?.twitterHandle && (
          <a
            href={`https://x.com/${token.tokenSocials.twitterHandle}`}
            target="_blank"
            rel="noreferrer"
            className="social-link">
            <XIcon />
            Twitter
          </a>
        )}
      </Box>
    </Box>
  );
};

export default TokenDescription;
