import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { NumericFormat, SourceInfo } from 'react-number-format';
import { useDebounce } from "use-debounce";
import { SWAP_TOKEN_TYPE } from '../../../constants';
import { ISwapToken } from "../../../interfaces/token";
import { AppStoreContext } from "../../../store";
import { swapOperationTitle } from "../../../utils/tokenUtils";
import { PriceUsd } from "./PriceUsd";
import { TokenBalance } from './TokenBalance';

interface IProps {
  token: ISwapToken
  tokenType: SWAP_TOKEN_TYPE
  onChange: (value: number | undefined) => void
}

export const ExchangeTokenBlock = observer((props: IProps) => {
  const store = useContext(AppStoreContext);
  const { token, tokenType, onChange } = props;
  const [innerValue, setInnerValue] = useState<number | undefined>(undefined); // for tracking debouncing
  const [debouncedValue] = useDebounce(innerValue, 500);
  const storeValueField = tokenType === SWAP_TOKEN_TYPE.BASE
    ? 'baseTokenInputValue'
    : 'quoteTokenInputValue'

  useEffect(() => {
    onChange(debouncedValue)
  }, [debouncedValue, onChange])

  const changeValueCallback = useCallback(async (values: any, source: SourceInfo) => {
    if (source.source as string === 'prop') {
      return;
    }

    setInnerValue(values.floatValue)
    store.setSwapInputValue(tokenType, values.floatValue)
  }, [store, tokenType])

  return (
    <Box className="exchanger--token-block" >
      <div className="exchanger--token-information">
        <div className="operation-title">
          {swapOperationTitle(store.swap.straightDirection, tokenType)}
        </div>
        <div className="image-and-symbol">
          <img
            width={22}
            height={22}
            src={token.imagePath}
            alt={`${token.symbol}-logo`}
          />
          <span>{token.symbol}</span>
        </div>
      </div>
      <div className="exchanger--input-container">
        { store.isWalletConnected ? <TokenBalance token={token} tokenType={tokenType} /> : <div className="wallet-placeholder"/> }
        <NumericFormat
          value={store.swap[storeValueField] || ''}
          className='exchanger--input'
          placeholder='0.00'
          inputMode='decimal'
          valueIsNumericString
          onValueChange={changeValueCallback}
          defaultValue={undefined}
          allowLeadingZeros={false}
          allowedDecimalSeparators={[',']}
        />
        <PriceUsd token={token} value={store.swap[storeValueField]} tokenType={tokenType}/>
      </div>
    </Box>
  )
})
