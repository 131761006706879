import LinkIcon from '@mui/icons-material/Link';
import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import copy from 'copy-to-clipboard';
import { observer } from 'mobx-react';
import { useCallback, useContext, useState } from 'react';
import missing from '../../assets/images/missing.png';
import { IToken } from '../../interfaces/token';
import { AppStoreContext } from '../../store';
import { compactSmall, numberAsDigitNotation } from '../../utils';
import PriceChange from '../PriceChange/PriceChange';
import StarIndicator from '../StarIndicator';

interface IProps {
  token: IToken;
}

const TokenMainInfo = observer(({ token }: IProps) => {
  const store = useContext(AppStoreContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const copyShare = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
    copy(window.location.href);
    const timeout = setTimeout(() => {
      setAnchorEl(null);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const priceToShow = numberAsDigitNotation(compactSmall(token.priceUsd));

  const sharePopoverOpen = Boolean(anchorEl);

  if (!token) return null;

  return (
    <Box className="token-main">
      <Box className="token-name-wrapper">
        <Box className="coin-name">
          <img
            src={
              !!token.imagePath
                ? process.env.REACT_APP_API_URL + token.imagePath
                : missing
            }
            height={30}
            width={30}
            alt="logo"
            className="coin-icon"
          />
          <h1 className="coin-text">{token?.tokenSocials?.tokenName}</h1>
          <span className="coin-text coin-title">{token?.symbol}</span>
        </Box>
        <Box className="top-button-wrapper">
          <button className="top-button share" onClick={copyShare}>
            Share
            <LinkIcon sx={{ marginLeft: '4px' }} />
          </button>
          <Popover
            className="default-popover"
            open={sharePopoverOpen}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}>
            Copied to clipboard.
          </Popover>
          <button className="top-button watchlist">
            <StarIndicator token={token.quoteTokenAddress} />
          </button>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <h2
          className={`token-main-price ${priceToShow.length > 10 && 'smaller'}`}>
          ${priceToShow}
        </h2>
        <Box className="token-main-percent">
          <PriceChange large value={token.priceChangePercentage.h24} />
        </Box>
      </Box>
    </Box>
  );
});

export default TokenMainInfo;
