import Box from '@mui/material/Box';
import { IToken } from '../../interfaces/token';

interface IProps {
  token: IToken;
}

const TokenAboutInfo = ({ token }: IProps) => {
  if (!token) return null;

  return (
    <Box id="tab-1" className="token-about">
      <Box className="token-about-label">About</Box>
      <Box>{token.tokenSocials?.description}</Box>
    </Box>
  );
};

export default TokenAboutInfo;
