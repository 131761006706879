import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { useCallback, useContext } from 'react';
import { AppStoreContext } from '../../store';
import TransparentButton from '../TransparentButton/TransparentButton';

const HotButtons = observer(() => {
  const store = useContext(AppStoreContext);

  const onClickWatchedOnly = useCallback(() => {
    store.setDisplayFilter('watchlist');
  }, [store]);

  const onClickAll = useCallback(() => {
    store.setDisplayFilter('all');
    store.setBubblesSort('default');
    store.setTableSortState({
      by: 'liquidity',
      direction: 'desc'
    });
  }, [store]);

  const onClickGainers = useCallback(() => {
    store.setDisplayFilter('all');
    store.setBubblesSort('gainers');
    store.setTableSortState({
      by: 'priceChangePercentage.h24',
      direction: 'desc'
    });
    store.setRepresentationTimeframe('h24');
  }, [store]);

  const onClickRecent = useCallback(() => {
    store.setDisplayFilter('all');
    store.setBubblesSort('recent');
    store.setTableSortState({
      by: 'poolCreatedAt',
      direction: 'desc'
    });
  }, [store]);

  return (
    <Box className="hot-buttons">
      <TransparentButton
        onClick={onClickAll}
        content="All"
        emoji="📈"
        isActive={
          store.filters.displayTokens === 'all' &&
          store.filters.bubblesSort === 'default'
        }
      />
      {store.currentUser && (
        <TransparentButton
          onClick={onClickWatchedOnly}
          content="Watched"
          emoji="👀"
          isActive={store.filters.displayTokens === 'watchlist'}
        />
      )}
      <TransparentButton
        onClick={onClickGainers}
        content="Top gainers"
        emoji="🚀"
        isActive={
          store.filters.displayTokens === 'all' &&
          store.filters.bubblesSort === 'gainers'
        }
        width={116}
      />
      <TransparentButton
        onClick={onClickRecent}
        content="Recently added"
        emoji="⏱️"
        isActive={
          store.filters.displayTokens === 'all' &&
          store.filters.bubblesSort === 'recent'
        }
        width={142}
      />
    </Box>
  );
});

export default HotButtons;
