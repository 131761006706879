import {
  Account,
  ConnectAdditionalRequest,
  TonProofItemReplySuccess
} from "@tonconnect/ui-react";
import { API_BASE_URL, LOCAL_STORAGE_TON_TOKEN_KEY } from "./constants";

class TonProofDemoApiService {
  private localStorageKey = LOCAL_STORAGE_TON_TOKEN_KEY;

  private host = API_BASE_URL;

  public accessToken: string | null = null;

  public readonly refreshIntervalMs = 9 * 60 * 1000;

  constructor() {
    this.accessToken = localStorage.getItem(this.localStorageKey);

    if (!this.accessToken) {
      this.generatePayload();
    }
  }

  async generatePayload(): Promise<ConnectAdditionalRequest | null> {
    try {
      const response = await (
        await fetch(`${this.host}ton-auth/generate-payload`, {
          method: 'POST',
        })
      ).json();
      return {tonProof: response.payload as string};
    } catch {
      return null;
    }
  }

  async checkProof(proof: TonProofItemReplySuccess['proof'], account: Account): Promise<void> {
    try {
      const reqBody = {
        address: account.address,
        network: account.chain,
        public_key: account.publicKey,
        proof: {
          ...proof,
          state_init: account.walletStateInit,
        },
      };

      const response = await (
        await fetch(`${this.host}ton-auth/check-proof`, {
          method: 'POST',
          body: JSON.stringify(reqBody),
          headers: { 'Content-Type': 'application/json' }
        })
      ).json();

      if (response?.token) {
        localStorage.setItem(this.localStorageKey, response.token);
        this.accessToken = response.token;
      }
    } catch (e) {
      console.log('checkProof error:', e);
    }
  }

  async getAccountInfo(account: Account) {
    const response = await (
      await fetch(`${this.host}ton-auth/get-account-info`, {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
          'Content-Type': 'application/json',
        },
      })
    ).json();

    return response as {};
  }

  reset() {
    this.accessToken = null;
    localStorage.removeItem(this.localStorageKey);
    this.generatePayload();
  }
}

export const TonProofDemoApi = new TonProofDemoApiService();
