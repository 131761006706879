import { unauthorizedRequest } from ".";
import { IPromotedTokensResponse } from "../interfaces/promotedToken";

const fetchPromotedTokens = async (): Promise<IPromotedTokensResponse | null> => {
  const response = await unauthorizedRequest('promoted-tokens');
  if (!response.success) {
    return null;
  }

  const promotedTokensResponse: IPromotedTokensResponse = await response.data;
  return promotedTokensResponse;
};

export { fetchPromotedTokens };
