import Box from '@mui/material/Box';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { fetchChart } from '../../endpoints/token';
import { IToken } from '../../interfaces/token';
import { AppStoreContext } from '../../store';
import TokenChart from './TokenChart';
import TokenChartControls from './TokenChartControls';

interface IProps {
  token: IToken;
}

const TokenChartContainer = observer(({ token }: IProps) => {
  const store = useContext(AppStoreContext);

  const { isError, isLoading, data, refetch } = useQuery({
    queryKey: [`chartData-${store.chart.chartType}-${store.chart.period}`],
    queryFn: () => fetchChart(token.quoteTokenAddress, store.chart.chartType, store.chart.period),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    refetch();
  }, [token.quoteTokenAddress, refetch]);

  const chartNotReady = !token || isError || isLoading

  return (
    <Box id="tab-0">
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <TokenChartControls />
      </Box>
      <Box className="token-chart">
        { chartNotReady ? <div style={{height: '400px'}} /> : <TokenChart records={data.records} loading={isLoading} chartType={store.chart.chartType} lastPriceUsd={token.priceUsd} /> }
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <TokenChartControls />
      </Box>
    </Box>
  );
})

export default TokenChartContainer;
