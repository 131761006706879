import Box from '@mui/material/Box';
import './Footer.scss';

function Footer() {
  return (
    <Box className="app-footer">
      <a
        className="social-link link"
        href="https://t.me/addicktedton"
        target="_blank"
        rel="noreferrer">
        By Addickted
      </a>
      <a
        className="social-link link"
        href="https://addickted.typeform.com/bubbles"
        target="_blank"
        rel="noreferrer">
        Advertise
      </a>
    </Box>
  );
}

export default Footer;
