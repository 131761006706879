import { round } from 'lodash';

export const formatCompact = (value?: number) => {
  if (!value) {
    return null;
  }
  if (value < 1000) {
    return value;
  } else if (value >= 1000 && value < 1_000_000) {
    return (value / 1000).toFixed(2).replace(/\.0$/, "") + "K";
  } else if (value >= 1_000_000 && value < 1_000_000_000) {
    return (value / 1_000_000).toFixed(2).replace(/\.0$/, "") + "M";
  } else if (value >= 1_000_000_000 && value < 1_000_000_000_000) {
    return (value / 1_000_000_000).toFixed(2).replace(/\.0$/, "") + "B";
  } else if (value >= 1_000_000_000_000 && value < 1_000_000_000_000_000) {
    return (value / 1_000_000_000_000).toFixed(2).replace(/\.0$/, "") + "T";
  }
}

export const formatCompactUsd = (value?: number | null) => {
  if (!value) {
    return null;
  }

  return `$${formatCompact(value)}`;
}

export const compactSmall = (value: number) => {
  if (value === 0) {
    return value
  }

  if (value > 1) { return round(value, 3) }
  if (value > 0.01) { return round(value, 4) }
  if (value > 0.001) { return round(value, 5) }
  if (value > 0.0001) { return round(value, 6) }
  if (value > 0.00001) { return round(value, 7) }
  if (value > 0.000001) { return round(value, 8) }
  if (value > 0.0000001) { return round(value, 9) }

  return round(value, 11);
}

export const numberAsDigitNotation = (value: number): string => {
  return value.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 11
  })
}

export const spliceFilter = (arr: [], fn: any) =>
  arr.filter(fn).reduce((acc, val) => {
    arr.splice(arr.indexOf(val), 1);
    return acc.concat(val);
  }, []);


export const queryId = (): bigint => {
  const multiplier = 1_000_000_000_000
  const randomNumber = BigInt(Math.floor(Math.random() * multiplier));
  const prefix = BigInt(444_444) * BigInt(multiplier);

  return prefix + randomNumber;
}

export const calculateUnits = (value: string | number | bigint | undefined, decimals: number): string => {
  if (!value) {
    return '0'
  }

  if (Number(value) > 1_000_000_000) {
    return (BigInt(value) * BigInt(10**decimals)).toString();
  }

  return (Number(value) * 10**decimals).toFixed(0)
}