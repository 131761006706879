/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck

import p5 from 'p5';
import missing from '../../assets/images/missing.png';
import { API_BASE_URL, DEFAULT_AD_BUBBLE_COLOR } from '../../constants';
import { IGameBubble } from '../../interfaces/bubble';
import { IPromotedToken } from '../../interfaces/promotedToken';
import { formatCompactUsd, spliceFilter } from '../../utils';
import amplitudeInstance from '../../utils/amplitude';
import googleAnalytics from '../../utils/bubblesGa';
import { promotedTokenImageURL } from '../../utils/promotedTokenUtils';
import { type CanvasPool } from '../models/token.model';
import { newCalculation } from './radiusTransformer';

const getFormattedTimer = (timer: number) => {
  if (timer <= 0) {
    return '';
  }

  let hours: string | number = parseInt(String(timer / 3600), 10);
  let minutes: string | number = parseInt(
    String((timer - hours * 3600) / 60),
    10
  );
  let seconds: string | number = parseInt(
    String(timer - hours * 3600 - minutes * 60),
    10
  );

  hours = hours < 1 ? `0${hours}` : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return `${hours}:${minutes}:${seconds}`;
};

const sketch = function (
  p: any,
  poolsCanvas: CanvasPool[],
  adsData: IPromotedToken | null,
  gameBubble: IGameBubble | null,
  startGameCallback: () => void,
  canvasRenderedCallback: () => void,
  gotToTokenPage: (token: string) => void
) {
  const startGame = startGameCallback;
  const bubbles = [] as any[];
  const repulsionStrength = 0.01;
  const velocityReductionFactor = 0.98;
  let selectedBubble: Bubble | null = null;
  let pressTime: number = 0;
  let pressLocation: p5.Vector = new p5.Vector(0, 0);
  let mouseHoveretOffset: p5.Vector = new p5.Vector(0, 0);
  let marketBubbleClosePressed: boolean = false;
  let cloud_1 = null as any;
  let cloud_2 = null as any;
  let fontTitle = null as any;
  let fontPrice = null as any;
  let minRadius: null | number = null;
  let maxRadius: null | number = null;
  let gameBubbleIntervalId: any = null;

  p.preload = function () {
    cloud_1 = p.loadImage('/cloud_1.svg');
    cloud_2 = p.loadImage('/cloud_2.svg');

    fontTitle = p.loadFont('fonts/Onest-Bold.ttf');
    fontPrice = p.loadFont('fonts/Onest-Regular.ttf');
  };

  p.setup = function () {
    const size = calculateSize();

    const numOfBubbles = Math.min(
      100,
      (size.width * size.height) / (100 * 100)
    );

    let array = [...poolsCanvas];

    // array = array.sort(
    //   (a, b) =>
    //     Math.abs(Number(b.price_change_percentage)) -
    //     Math.abs(Number(a.price_change_percentage))
    // );
    array.splice(numOfBubbles);

    let priceOfPools: number[] = array.map((pool) => {
      return pool?.price_change_percentage
        ? Math.abs(Number(pool.price_change_percentage))
        : 0;
    });

    const myCanvas = p.createCanvas(size.width, size.height);

    const radiuses = newCalculation(priceOfPools, size.width * size.height);

    maxRadius = Math.max(...radiuses);
    minRadius = Math.min(...radiuses);

    Promise.all(
      radiuses.map((radius, index) => createBubble(array[index], radius))
    ).then(() => {
      myCanvas.parent('sketch-id');
      myCanvas.style('id', 'sketch-container');
    });

    cloud_1 = p.loadImage('/cloud_1.svg');
    cloud_2 = p.loadImage('/cloud_2.svg');

    if (adsData /* && adsData.enabled */) {
      setTimeout(() => {
        addADSBubble(minRadius, maxRadius * 1.1, adsData);
      }, 2000);
    }

    if (gameBubble?.shown) {
      p.renderGameBubble(gameBubble);
    }

    canvasRenderedCallback();
  };

  p.windowResized = function () {
    const wrapper = document.getElementById('bubbles-page');
    p.resizeCanvas(wrapper?.offsetWidth - 2, window.innerHeight - 70);
  };

  function addGameBubble(targetRadius: number) {
    if (bubbles.some((bubble) => bubble.isGameBubble)) {
      return;
    }
    const x = p.random(p.width);
    const y = p.random(p.height);

    const newBubble = new Bubble(
      x,
      y,
      targetRadius,
      targetRadius,
      {
        color: '#0B7D76'
      },
      null,
      null,
      false,
      true
    );

    bubbles.push(newBubble);
  }

  function addADSBubble(
    startRadius: number,
    radius: number,
    adsData: IPromotedToken
  ) {
    if (bubbles.some((bubble) => bubble.isMarketing)) {
      return;
    }

    googleAnalytics.event('show_promoted_bubble', {
      token_name: adsData.name
    });

    const x = p.random(p.width);
    const y = p.random(p.height);
    const targetRadius = radius;
    const adsColor = {
      fill: p.color(0, 136, 204, 10),
      stroke: p.color(0, 136, 204)
    };

    const tokenImageUrl = promotedTokenImageURL(adsData);

    const adsImage = p.loadImage(tokenImageUrl);
    const newBubble = new Bubble(
      x,
      y,
      startRadius,
      targetRadius,
      {
        name: adsData.name,
        ads_link: adsData.url,
        price_change_percentage: 3000,
        image_url: tokenImageUrl,
        color: adsData.backgroundColor || DEFAULT_AD_BUBBLE_COLOR,
        description: adsData.name,
        symbol: adsData.symbol
      },
      adsImage,
      adsColor,
      true
    );

    bubbles.push(newBubble);

    if (adsData.timeout) {
      setTimeout(
        () => {
          bubbles.find((bubble, index) => {
            if (bubble === newBubble) {
              bubbles.splice(index, 1);
              return true;
            }
          });
        },
        Number(adsData.timeout) * 1000
      );
    }
  }

  async function createBubble(pool: CanvasPool, radius: number) {
    const maxAttempts = 100; // Maximum attempts to create a non-overlapping bubble
    let attempts = 0;
    let newBubble;
    let image = null;

    if (pool?.image_url) {
      const url = pool.image_url.startsWith('http')
        ? pool.image_url
        : API_BASE_URL + pool.image_url;
      image = await p.loadImage(url);
    } else {
      image = await p.loadImage(missing);
    }

    while (attempts < maxAttempts) {
      const x = p.random(p.width);
      const y = p.random(p.height);

      let overlapping = false;

      for (const bubble of bubbles) {
        const d = p.dist(x, y, bubble.position.x, bubble.position.y);
        if (d < (radius + bubble.radius) * 0.8) {
          overlapping = true;
          break;
        }
      }

      if (!overlapping) {
        newBubble = new Bubble(x, y, radius, radius, pool, image, null);
        bubbles.push(newBubble);
        break; // Exit the loop if a non-overlapping bubble is created
      }

      attempts++;
    }

    // If maximum attempts reached and no non-overlapping bubble was created,
    // just add the last attempted bubble even if it overlaps
    if (attempts === maxAttempts) {
      newBubble = new Bubble(
        p.random(p.width),
        p.random(p.height),
        radius,
        radius,
        pool,
        image,
        null
      );
      bubbles.push(newBubble);
    }
  }

  p.draw = function () {
    p.background('white');

    let mouseOverBubble = false;

    for (let i = 0; i < bubbles.length; i++) {
      bubbles[i].update();
      bubbles[i].display();

      if (bubbles[i].hovered) {
        mouseOverBubble = true;
      }
    }

    if (!mouseOverBubble) {
      p.cursor(p.ARROW);
    }
  };

  p.touchStarted = function () {
    this.mousePressed();
  };

  p.touchMoved = function () {
    this.mouseDragged();
  };

  p.touchEnded = function (e) {
    this.mouseReleased(e);
  };

  p.touchClicked = function () {
    this.mouseClicked();
  };

  p.mousePressed = function () {
    // Check if mouse is over any bubble and select it
    pressTime = new Date().getTime();
    pressLocation = new p5.Vector(p.mouseX, p.mouseY, 0);
    const marketingBubble = bubbles.find((x) => x.isMarketing);

    if (marketingBubble && marketingBubble?.isCloseButtonClicked())
      marketBubbleClosePressed = true;
    for (const bubble of bubbles) {
      const d = p.dist(
        p.mouseX,
        p.mouseY,
        bubble.position.x,
        bubble.position.y
      );
      if (d < bubble.radius) {
        mouseHoveretOffset.x = p.mouseX - bubble.position.x;
        mouseHoveretOffset.y = p.mouseY - bubble.position.y;
        selectedBubble = bubble;

        // Disable scrolling when a bubble is selected
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        break;
      }
    }
  };

  p.mouseDragged = function () {
    // If a bubble is selected, move it with the mouse
    if (selectedBubble) {
      selectedBubble.position.x = p.mouseX - mouseHoveretOffset.x;
      selectedBubble.position.y = p.mouseY - mouseHoveretOffset.y;
    }
  };

  p.mouseReleased = function (event: { target: HTMLCanvasElement }) {
    if (!event.target?.closest('#sketch-id')) {
      return;
    }

    // Reset selected bubble after mouse release
    const releaseTime = new Date().getTime();
    const releaseLocation = new p5.Vector(p.mouseX, p.mouseY, 0);

    if (
      releaseTime - pressTime < 100 &&
      selectedBubble &&
      selectedBubble.isRegular()
    ) {
      gotToTokenPage(selectedBubble.pool?.quoteTokenAddress);
      googleAnalytics.modalView('token_view_modal', {
        token_name: selectedBubble.pool?.symbol,
        event_source: 'bubble_click'
      });
    }

    // if less then 0.2 seconds and press location ~= equal release location
    if (
      releaseTime - pressTime < 200 &&
      releaseLocation.dist(pressLocation) < 15
    ) {
      if (marketBubbleClosePressed) {
        bubbles.some((bubble, index) => {
          if (bubble.isMarketing) {
            bubbles.splice(index, 1);
            return true;
          }
          return false;
        });
        marketBubbleClosePressed = false;
      } else if (
        selectedBubble?.isMarketing &&
        selectedBubble?.pool?.ads_link
      ) {
        googleAnalytics.event('ad_click', {
          url: selectedBubble.pool.ads_link,
          event_source: 'marketing_bubble'
        });
        amplitudeInstance.event('adClick', {
          place: 'bubble',
          url: selectedBubble.pool.ads_link
        });
        window.open(selectedBubble.pool.ads_link, '_blank');
      } else if (selectedBubble?.isGameBubble && !p.pointsBubbleTimer) {
        startGame();
        document
          .querySelector('#popBubbleButton')
          ?.dispatchEvent(new Event('click'));
      }
    }

    // Reset selected bubble
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    selectedBubble = null;
  };

  // p.mouseClicked = function () {
  //   // Check if mouse is over any bubble and remove it
  //   for (let i = bubbles.length - 1; i >= 0; i--) {
  //     if (bubbles[i].showCloseButton && bubbles[i].isCloseButtonClicked()) {
  //       bubbles.splice(i, 1);
  //       break;
  //       //} else if (bubbles[i].hovered && bubbles[i].pool.ads_link) {
  //       // Open link in a new tab if the bubble is hovered and has an ads_link
  //       //window.open(bubbles[i].pool.ads_link, "_blank");
  //       //break;
  //     }
  //   }
  // };

  class Bubble {
    position: any;
    endRadius: any;
    radius: any;
    velocity: p5.Vector;
    color: string | null;
    strokeColor: string;
    pool: CanvasPool;
    image: any;
    hovered: boolean = false;
    isMarketing: boolean = false;
    isGameBubble: boolean = false;
    pointsBubbleTimer: number = 0;

    constructor(
      x: any,
      y: any,
      radius: any,
      endRadius: any,
      pool: CanvasPool,
      image: any | null,
      color: { fill: string; stroke: string } | null,
      isMarketing: boolean = false,
      isGameBubble: boolean = false
    ) {
      this.position = p.createVector(x, y);
      this.velocity = p5.Vector.random2D().mult(p.random(0.5, 2));
      this.endRadius = endRadius;
      this.radius = radius;
      this.pool = pool;
      this.image = image;
      this.isMarketing = isMarketing;
      this.isGameBubble = isGameBubble;
      this.pointsBubbleTimer = 0;

      if (pool?.color) {
        const rgba = this.hexToRgbA(pool.color);
        const arryaofrgba = rgba.split(',');
        this.color = p.color(
          arryaofrgba[0],
          arryaofrgba[1],
          arryaofrgba[2],
          65
        );
        this.strokeColor = p.color(
          arryaofrgba[0],
          arryaofrgba[1],
          arryaofrgba[2]
        );
      } else {
        if (pool.positive_dynamic) {
          this.color = p.color(157, 214, 98, 65);
          this.strokeColor = p.color('#8FB469');
        } else {
          this.color = p.color(252, 151, 144, 65);
          this.strokeColor = p.color('#F65039');
        }
      }
    }

    hexToRgbA(hex: any) {
      var c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',85';
      }
      throw new Error('Bad Hex');
    }

    isCloseButtonClicked() {
      const d = p.dist(
        p.mouseX,
        p.mouseY,
        this.position.x + this.radius / 1.4,
        this.position.y - this.radius / 1.4
      );
      return d < 16; // Adjust the radius as needed
    }

    isRegular(): boolean {
      return !this.isGameBubble && !this.isMarketing;
    }

    update() {
      this.hovered = this.isMouseOver();

      // Grow bubble
      if (
        (this.isMarketing || this.isGameBubble) &&
        this.radius < this.endRadius
      ) {
        this.radius += 0.2;
      }

      // Bounce off walls
      if (this.position.x + this.radius >= p.width) {
        this.position.x = p.width - this.radius;
        this.velocity.x *= -1;
      } else if (this.position.x - this.radius <= 0) {
        this.position.x = this.radius;
        this.velocity.x *= -1;
      }
      if (this.position.y + this.radius >= p.height) {
        this.position.y = p.height - this.radius;
        this.velocity.y *= -1;
      } else if (this.position.y - this.radius <= 0) {
        this.position.y = this.radius;
        this.velocity.y *= -1;
      }

      // repel bubbles
      for (const other of bubbles) {
        if (other !== this) {
          const d = p.dist(
            this.position.x,
            this.position.y,
            other.position.x,
            other.position.y
          );
          const radiusSum = this.radius + other.radius;
          if (d < radiusSum) {
            const repelForce = p5.Vector.sub(
              this.position,
              other.position
            ).normalize();
            const deepness = Math.pow(radiusSum / d, 4);
            const diff = Math.pow(other.radius / this.radius, 3);
            repelForce.setMag(repulsionStrength * diff * deepness);
            this.velocity.add(repelForce);
          }
        }
      }

      // Decrease velocity
      this.velocity = this.velocity.limit(10);
      this.position.add(this.velocity);
      this.velocity.mult(velocityReductionFactor);
    }

    drawReflections(bottomColor: string) {
      // Draw reflection lines
      const reflectionColorTopLeft = p.color(255, 255, 255, 255 / 2); // Lighter color for reflection
      const reflectionColorBottomRight = p.color(
        bottomColor.levels[0],
        bottomColor.levels[1],
        bottomColor.levels[2],
        255 / 2
      ); // Lighter color for reflection
      const reflectionThickness = this.radius / 30; // Thickness of the reflection lines
      const numPoints = 100; // Number of points to approximate the circle
      const reflectionRadiusFactorTopLeft = 0.92;
      const reflectionRadiusFactorBottomRight = 0.94;

      // Calculate points for reflection lines at top left
      p.stroke(reflectionColorTopLeft);
      p.strokeWeight(reflectionThickness);
      p.noFill();
      p.beginShape();
      for (let i = 0; i < numPoints; i++) {
        const angle = p.PI + 10 + (i * p.TWO_PI) / numPoints / 8; // Start from the top
        const x =
          this.position.x -
          this.radius * reflectionRadiusFactorTopLeft * p.cos(angle);
        const y =
          this.position.y -
          this.radius * reflectionRadiusFactorTopLeft * p.sin(angle);
        if (x <= this.position.x && y <= this.position.y) {
          p.vertex(x, y);
        }
      }
      p.endShape();

      // Calculate points for reflection lines at bottom right
      p.stroke(reflectionColorBottomRight);
      p.beginShape();
      for (let i = 0; i < numPoints; i++) {
        const angle = p.PI + 10 + (i * p.TWO_PI) / numPoints / 14; // Start from the bottm
        const x =
          this.position.x +
          this.radius * reflectionRadiusFactorBottomRight * p.cos(angle);
        const y =
          this.position.y +
          this.radius * reflectionRadiusFactorBottomRight * p.sin(angle);
        if (x >= this.position.x && y >= this.position.y) {
          p.vertex(x, y);
        }
      }
      p.endShape();
    }

    display() {
      if (p.pointsBubbleTimer && this.isGameBubble) {
        p.stroke(this.strokeColor);
        p.strokeWeight(this.radius / 30);
        p.fill(this.color);
        p.ellipseMode(this.radius * 2);
        p.ellipse(this.position.x, this.position.y, this.radius * 2);

        this.drawReflections(this.strokeColor);

        p.noStroke();

        p.textFont(fontTitle);
        p.textStyle(p.BOLD);

        p.textSize(this.radius / 3);
        p.fill('#0B7D76');
        p.text(
          getFormattedTimer(p.pointsBubbleTimer),
          this.position.x,
          gameBubble?.timeLeftSeconds ? this.position.y : this.position.y
        );

        return;
      }

      p.stroke(this.strokeColor);
      p.strokeWeight(this.radius / 30);
      p.fill(this.color);
      p.ellipseMode(this.radius * 2);
      p.ellipse(this.position.x, this.position.y, this.radius * 2);

      this.drawReflections(this.strokeColor);

      p.noStroke();

      if (this.image) {
        p.fill(255);
        p.rectMode(p.CENTER);
        p.rect(
          this.position.x,
          this.position.y - this.radius / 2,
          this.radius / 2,
          this.radius / 2,
          this.radius / 2
        );

        p.imageMode(p.CENTER);

        //console.log('image', this.image);
        // if (this.image) p.texture(this.image);
        // p.circle(
        //   this.position.x,
        //   this.position.y - this.radius / 2,
        //   this.radius / 2
        // );

        //console.log('image', this.image);
        // p.drawingContext.shadowOffsetX = 5;
        // p.drawingContext.shadowOffsetY = -5;
        // p.drawingContext.shadowBlur = 10;
        // p.drawingContext.shadowColor = 'black';
        p.push();
        p.clip(() => {
          p.circle(
            this.position.x,
            this.position.y - this.radius / 2,
            this.radius / 2
          );
        });
        p.image(
          this.image,
          this.position.x,
          this.position.y - this.radius / 2,
          this.radius / 2,
          this.radius / 2
        );
        p.pop();
      }

      p.textFont(fontTitle);
      p.textStyle(p.BOLD);

      if (this.isGameBubble) {
        p.textSize(this.radius / 3);
        p.fill('#0B7D76');
        p.text(
          'START\nFARMING',
          this.position.x,
          this.position.y - this.radius / (8 / 2)
        );
      } else {
        const textContent = this.pool.symbol.slice(0, 7).toUpperCase();
        const textSizeCoef = textContent.length > 5 ? 3 : 2.5;
        p.textSize(this.radius / textSizeCoef);
        p.fill(this.isMarketing ? this.strokeColor : 0);

        p.text(
          textContent,
          this.position.x,
          this.image
            ? this.position.y - this.radius / 128
            : this.position.y - this.radius / (8 / 2)
        );
      }

      p.fill(this.strokeColor);

      p.textFont(fontPrice);
      p.textSize(this.radius / (this.pool?.type === 'price' ? 2.9 : 4));
      if (this.pool?.ads_link) {
        p.textSize(this.radius / 5);
        p.text(
          this.pool.description?.replace(/\\n/g, '\n'), // newline val from the database
          this.position.x,
          this.image
            ? this.position.y + this.radius / (this.isMarketing ? 2 : 2.5)
            : this.position.y + this.radius / 4
        );
      } else if (this.isGameBubble) {
        p.textSize(this.radius / 8);
        p.text(
          'Burst the farming bubble\nto start farming',
          this.position.x,
          this.position.y + this.radius / 3
        );
      } else {
        p.text(
          this.pool.type === 'price'
            ? `${this.pool.price_change_percentage.toFixed(2)}%`
            : formatCompactUsd(this.pool?.price_change_percentage),
          this.position.x,
          this.image
            ? this.position.y + this.radius / 2.5
            : this.position.y + this.radius / 4
        );
      }
      p.textAlign(p.CENTER, p.CENTER);

      if (this.hovered && this.isMarketing) {
        p.fill(this.strokeColor);
        p.noStroke();
        p.rectMode(p.CENTER);
        p.ellipse(
          this.position.x + this.radius / 1.4,
          this.position.y - this.radius / 1.4,
          this.radius / 4,
          this.radius / 4
        );
        p.fill(255);
        p.textAlign(p.CENTER, p.CENTER);
        p.textSize(this.radius / 6);
        p.text(
          'X',
          this.position.x + this.radius / 1.4,
          this.position.y - this.radius / 1.35
        );
      }
    }

    isMouseOver() {
      // Check if mouse is over the bubble
      const d = p.dist(p.mouseX, p.mouseY, this.position.x, this.position.y);
      if (d < this.radius + 15) {
        p.cursor(p.HAND);
        return true;
      } else {
        return false;
      }
    }
  }

  function calculateSize() {
    const wrapper = document.getElementById('bubbles-page');
    return {
      width: wrapper.offsetWidth - 2,
      height: window.innerHeight - 70
    };
  }

  p.removeRegularBubbles = function () {
    spliceFilter(bubbles, (x) => !x?.isMarketing && !x?.isGameBubble);
  };

  p.redrawBubbles = async function (newBubbles: CanvasPool[]) {
    p.removeRegularBubbles();
    if (!newBubbles.length) {
      return;
    }
    const size = calculateSize();
    const numOfBubbles = Math.min(
      100,
      (size.width * size.height) / (100 * 100)
    );
    let array = [...newBubbles];
    // array = array.sort(
    //   (a, b) =>
    //     Math.abs(Number(b.price_change_percentage)) -
    //     Math.abs(Number(a.price_change_percentage))
    // );
    array.splice(numOfBubbles);

    let priceOfPools: number[] = array.map((pool) => {
      return pool?.price_change_percentage
        ? Math.abs(Number(pool.price_change_percentage))
        : 0;
    });

    const radiuses = newCalculation(priceOfPools, size.width * size.height);
    return await Promise.all(
      radiuses.map((radius, index) => createBubble(array[index], radius))
    );
  };

  p.renderPromotedBubble = async function (promotedBubble: IPromotedToken) {
    setTimeout(() => {
      addADSBubble(minRadius, maxRadius * 1.1, promotedBubble);
    }, 2000);
  };

  // Should happen on logout
  p.removeGameBubble = async function () {
    this.pointsBubbleTimer = 0;
    const index = bubbles.findIndex((bubble) => bubble.isGameBubble);
    if (index) {
      bubbles.splice(index, 1);
    }
  };

  p.renderGameBubble = async function (timerBubble: IGameBubble) {
    setTimeout(() => {
      addGameBubble(maxRadius * 1.1);
    }, 0);

    if (timerBubble?.timeLeftSeconds) {
      this.pointsBubbleTimer = timerBubble.timeLeftSeconds;
      clearInterval(gameBubbleIntervalId);

      gameBubbleIntervalId = setInterval(() => {
        if (this.pointsBubbleTimer - 1 <= 0) {
          clearInterval(gameBubbleIntervalId);
          this.pointsBubbleTimer = 0;
        } else {
          this.pointsBubbleTimer -= 1;
        }
      }, 1000);
    }
  };
};

export default function initSketch(
  filteredPools: CanvasPool[],
  adsData: IPromotedToken | null,
  gameBubble: IGameBubble | null,
  startGameCallback: () => void,
  canvasRenderedCallback: () => void,
  gotToTokenPage: (token: string) => void
) {
  const sketchInstance = new p5((p: any) =>
    sketch(
      p,
      filteredPools,
      adsData,
      gameBubble,
      startGameCallback,
      canvasRenderedCallback,
      gotToTokenPage
    )
  );

  return sketchInstance;
}
