import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { IPromotedToken } from '../../interfaces/promotedToken';
import { IToken } from '../../interfaces/token';
import googleAnalytics from '../../utils/bubblesGa';
import { promotedTokenImageURL } from '../../utils/promotedTokenUtils';
import { tokenImageURL } from '../../utils/tokenUtils';
import { transformNumber } from '../../utils/transformNumber';
import PriceChange from '../PriceChange/PriceChange';
import './BannerComponent.scss';

interface IProps {
  type: string;
  label: string;
  icon: string;
  mr: number;
  rows: IToken[];
  token: IPromotedToken | null;
}

const BannerComponent = ({ type, label, icon, mr, rows, token }: IProps) => {
  const navigate = useNavigate();

  const goToLink = () => {
    googleAnalytics.event('ad_click', {
      url: token?.url,
      event_source: 'ad_table_row'
    });
    window.open(token?.url, '_blank');
  };

  const imageURL = token ? promotedTokenImageURL(token as IPromotedToken) : '';

  return (
    <Box
      className={`banner-component ${type === 'promoted' ? 'ad-banner' : ''}`}
      mr={mr}>
      <Box className="banner-label">
        {!!icon && <span>{icon}</span>}{' '}
        {type !== 'promoted' ? label : token?.symbol}
      </Box>
      {type !== 'promoted' && (
        <Box className="banner-list">
          {rows.map((token, index) => {
            return (
              <Box
                key={index}
                className="list-item"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                onClick={() => {
                  navigate(`/tokens/${token.quoteTokenAddress}`);
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box className="list-index">{index + 1}</Box>
                  <img
                    width={24}
                    height={24}
                    src={tokenImageURL(token)}
                    alt="logo"
                    className="logo-image"
                  />
                  <Box className="banner-token-name">
                    {token?.tokenSocials?.tokenName}
                  </Box>
                  <Box className="token-symbol">${token.symbol}</Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <Box className="price">
                    ${transformNumber(token?.priceUsd)}
                  </Box>
                  <Box sx={{ minWidth: '60px', marginLeft: '8px' }}>
                    <PriceChange
                      value={token?.priceChangePercentage?.h24 || ''}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
      {type === 'promoted' && (
        <Box className="banner-promoted">
          <Box
            className="ad-token"
            onClick={() => {
              goToLink();
            }}>
            <Box className="ad-token-icon">
              <img width={153} height={153} src={imageURL} alt="logo" />
            </Box>
            <Box className="ad-token-info">
              <Box className="ad-token-name">${token?.symbol}</Box>
              <Box className="ad-token-desc">
                <span>{token?.description}</span>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BannerComponent;
