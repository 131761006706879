import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';
import { TOKENS_PER_PAGE, TableSortValueMapping } from '../../constants';
import { IPromotedTokensResponse } from '../../interfaces/promotedToken';
import { IToken } from '../../interfaces/token';
import { AppStoreContext } from '../../store';
import googleAnalytics from '../../utils/bubblesGa';
import { onScroll } from '../../utils/filersScroll';
import { onHeaderScroll } from '../../utils/tableHeaderScroll';
import { sortTokens } from '../../utils/tokenUtils';
import { TableAdRow } from './TableAdRow';
import './TableComponent.css';
import { TokenTableRow } from './TokenTableRow';

interface IProps {
  allTokens: IToken[];
  promotedTokens: IPromotedTokensResponse | null;
}

const TableComponent = observer(({ allTokens, promotedTokens }: IProps) => {
  const store = useContext(AppStoreContext);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (!promotedTokens?.marketingPlaces.topTableRow) {
      return;
    }

    googleAnalytics.event('show_promoted_row', {
      token_name: promotedTokens?.marketingPlaces.topTableRow?.name
    });
  }, [promotedTokens?.marketingPlaces.topTableRow]);

  const onScrollTable = () => {
    var elmnt = document.getElementById('table-container');
    var elmnt2 = document.getElementById('table-header');

    if (elmnt && elmnt2) elmnt2.scrollLeft = elmnt.scrollLeft;
  };

  useEffect(() => {
    window.addEventListener('scroll', onHeaderScroll);
    window.addEventListener('resize', onHeaderScroll);
    window.addEventListener('resize', onScroll);
    let elmnt = document.getElementById('table-container');
    if (elmnt) {
      elmnt.addEventListener('scroll', onScrollTable);
    }
    return () => {
      window.removeEventListener('scroll', onHeaderScroll);
      window.removeEventListener('resize', onHeaderScroll);
      window.removeEventListener('resize', onScroll);
      let elmnt = document.getElementById('table-container');
      if (elmnt) {
        elmnt.removeEventListener('scroll', onScrollTable);
      }
    };
  }, []);

  useEffect(() => {
    onScroll();
  }, [page, store.filters.displayTokens]);

  useEffect(() => {
    setPage(1);
  }, [store.filters.displayTokens]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  const tableHeadCell = (item: { key: string; name: string }) => {
    const onClick = () => {
      const direction =
        store.tableSort.by !== item.key
          ? 'desc'
          : store.tableSort.direction === 'desc'
            ? 'asc'
            : 'desc';

      store.setTableSortState({
        by: item.key,
        direction: direction
      });
    };

    return (
      <TableCell
        key={item.name}
        sx={{ cursor: 'pointer' }}
        className={item.name.toLowerCase()}
        onClick={onClick}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
          <Box className="header-arrows">
            {store.tableSort.by === item.key && (
              <>
                {store.tableSort.direction === 'asc' ? (
                  <ArrowDropUpRoundedIcon />
                ) : (
                  <ArrowDropDownRoundedIcon />
                )}
              </>
            )}
          </Box>
          {item.name}
        </Box>
      </TableCell>
    );
  };

  const sortedTokens: IToken[] = useDeepCompareMemo(() => {
    return sortTokens(
      [...allTokens],
      store.tableSort.by,
      store.tableSort.direction
    );
  }, [allTokens, store.tableSort.by, store.tableSort.direction]);

  const displayedTokens = sortedTokens.slice(
    (page - 1) * TOKENS_PER_PAGE,
    (page - 1) * TOKENS_PER_PAGE + TOKENS_PER_PAGE
  );

  if (!allTokens) return null;

  return (
    <Box id="table-wrapper" className="table-wrapper">
      <TableContainer
        id="table-container"
        component={Paper}
        sx={{
          maxHeight: '400px',
          borderRadius: '0px 0px 18px 18px',
          backgroundColor: '#ffffff'
        }}>
        <Table sx={{ width: 'auto' }} aria-label="simple table">
          <TableHead id="table-header-main">
            <TableRow>
              <TableCell className="watchlist"></TableCell>
              <TableCell className="project">Project</TableCell>
              {TableSortValueMapping.map((item) => tableHeadCell(item))}
              <TableCell className="last"></TableCell>
            </TableRow>
          </TableHead>
          <TableHead id="table-header">
            <TableRow>
              <TableCell className="watchlist"></TableCell>
              <TableCell className="project">Project</TableCell>
              {TableSortValueMapping.map((item) => tableHeadCell(item))}
              <TableCell className="last"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {promotedTokens?.marketingPlaces.topTableRow && (
              <TableAdRow row={promotedTokens.marketingPlaces.topTableRow} />
            )}
            {displayedTokens.map((row: IToken, index: number) => (
              <TokenTableRow key={row.id} row={row} isAdRow={false} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="pagination">
        <Pagination
          id="pagination"
          count={Math.ceil(sortedTokens.length / 20)}
          onChange={handleChangePage}
          page={page}
          siblingCount={0}
          boundaryCount={1}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              {...item}
            />
          )}
        />
      </Box>
    </Box>
  );
});

export default TableComponent;
