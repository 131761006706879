import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { IToken } from '../../interfaces/token';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const StyledTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  minHeight: '40px',
  overflow: 'visible !important',
  '& .MuiTabs-scroller': {
    overflow: 'visible !important'
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    bottom: '-1px',
    '& > span': {
      maxWidth: 48,
      width: '100%',
      backgroundColor: '#161616'
    }
  }
});

const StyledTab = styled(Tab)({
  textTransform: 'none',
  padding: '8px 6px',
  minHeight: '40px',
  minWidth: '80px',
  color: '#798686',
  fontSize: '14px',
  fontFamily: 'Onest Variable',
  letterSpacing: '-0.02em',
  fontWeight: '580',
  '&.Mui-selected': {
    color: '#161616'
  }
});

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && children}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}-button`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

interface IProps {
  token: IToken;
}

const TokenTabs = ({ token }: IProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const element = document.getElementById(`tab-${newValue}`);
    element?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box mr={'-12px'} ml={'-12px'} sx={{ paddingTop: '8px' }}>
        <StyledTabs
          value={value}
          TabIndicatorProps={{ children: <span /> }}
          onChange={handleChange}
          aria-label="basic tabs example">
          <StyledTab label="Chart" {...a11yProps(0)} />
          {token.tokenSocials?.description && (
            <StyledTab label="About" {...a11yProps(1)} />
          )}
        </StyledTabs>
      </Box>
    </Box>
  );
};

export default TokenTabs;
