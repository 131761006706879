import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import missing from '../../assets/images/missing.png';
import { API_BASE_URL } from '../../constants';
import { IToken } from '../../interfaces/token';
import { AppStoreContext } from '../../store';
import { compactSmall, numberAsDigitNotation } from '../../utils';
import amplitudeInstance from '../../utils/amplitude';
import googleAnalytics from '../../utils/bubblesGa';
import PriceChange from '../PriceChange/PriceChange';
import StarIndicator from '../StarIndicator';

const usdOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};

interface IProps {
  row: IToken;
  isAdRow?: boolean;
  url?: string;
}

export const TokenTableRow = observer(({ row, isAdRow, url }: IProps) => {
  const store = useContext(AppStoreContext);
  const navigate = useNavigate();
  // const openWindow = (symbol: string, event: any) => {
  //   const elementClassList = event.target.classList;
  //   if (elementClassList.contains('open-block') || !elementClassList.length) {
  //     return;
  //   }
  //   store.setSelectedBubbleCode(symbol);
  // };

  const gotToTokenPage = (token: string, symbol: string, event: any) => {
    const elementClassList = event.target.classList;
    if (elementClassList.contains('open-block') || !elementClassList.length) {
      return;
    }

    googleAnalytics.modalView('token_view_modal', {
      token_name: symbol,
      event_source: 'table_row_click'
    });

    store.canvasRendered(false);
    navigate(`/tokens/${token}`);
  };

  const goToLink = () => {
    googleAnalytics.event('ad_click', {
      url: url,
      event_source: 'ad_table_row'
    });
    amplitudeInstance.event('adClick', {
      place: 'tableTopRow',
      url: url
    });
    window.open(url, '_blank');
  };

  return (
    <TableRow
      className={isAdRow ? 'ad-row' : ''}
      key={row.symbol}
      onClick={(e) =>
        isAdRow
          ? goToLink()
          : gotToTokenPage(row.quoteTokenAddress, row.symbol, e)
      }>
      <TableCell
        className="watchlist"
        sx={{
          position: 'sticky',
          left: 0,
          background: 'white'
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          {!isAdRow && <StarIndicator token={row.quoteTokenAddress} />}
        </Box>
      </TableCell>
      <TableCell
        className="project"
        sx={{
          position: 'sticky',
          left: 0,
          background: 'white'
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            width={30}
            height={30}
            src={!!row.imagePath ? API_BASE_URL + row.imagePath : missing}
            alt="logo"
            className="logo-image"
          />
          <Box className="name-symbol">
            <Box className="token-name">{row?.tokenSocials?.tokenName}</Box>
            <Box className="token-symbol">${row.symbol}</Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        ${numberAsDigitNotation(compactSmall(row.priceUsd))}
      </TableCell>
      <TableCell>
        {Number(row.marketCapUsd).toLocaleString('en-US', usdOptions)}
      </TableCell>
      <TableCell>
        {Number(row.liquidity).toLocaleString('en-US', usdOptions)}
      </TableCell>
      <TableCell>
        {row.volumeUsd?.h24
          ? Number(row?.volumeUsd?.h24).toLocaleString('en-US', usdOptions)
          : row?.volumeUsd?.h24}
      </TableCell>
      <TableCell>
        <PriceChange value={row?.priceChangePercentage?.h1 || ''} />
      </TableCell>
      <TableCell>
        <PriceChange value={row?.priceChangePercentage?.h6 || ''} />
      </TableCell>
      <TableCell>
        <PriceChange value={row?.priceChangePercentage?.h24 || ''} />
      </TableCell>
      <TableCell>
        <PriceChange value={row?.priceChangePercentage?.d7 || ''} />
      </TableCell>
      <TableCell>
        <PriceChange value={row?.priceChangePercentage?.d30 || ''} />
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
});
