import Box from '@mui/material/Box';
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { ReactComponent as UpsideDownIcon } from '../../assets/images/upside-down.svg';
import { SWAP_TOKEN_TYPE } from '../../constants';
import useSimulateSwap from '../../hooks/useSimulateSwap';
import { ISimulateSwapResponse } from '../../interfaces/stonFi';
import { ISwapToken } from '../../interfaces/token';
import { AppStoreContext } from "../../store";
import { calculateUnits } from '../../utils';
import Logger from '../../utils/logger';
import "./Exchanger.scss";
import { ExchangeButton } from './ExchangerButton';
import { ExchangeTokenBlock } from './ExchangeTokenBlock';
import { SwapHeader } from './SwapHeader';
import { SwapInformation } from './SwapInformation';


interface IProps {
  token: ISwapToken
}

export const Exchanger = observer(({ token }: IProps) => {
  const store = useContext(AppStoreContext);
  const [quoteToken, setQuoteToken] = useState<ISwapToken>(token);

  useEffect(() => {
    store.setSwapQuoteToken(token);
    setQuoteToken(token);
  }, [store, setQuoteToken, token]);

  const { simulateSwap, simulateReverseSwap } = useSimulateSwap();

  const baseTokenValueChanged = useCallback(async (value: number | string |undefined) => {
    if (!value) {
      store.setSimulatedSwap(null)
      store.setSwapInputValue(SWAP_TOKEN_TYPE.BASE, undefined)
      store.setSwapInputValue(SWAP_TOKEN_TYPE.QUOTE, undefined)
      return
    }

    const valueInUnits = calculateUnits(value, store.swap.baseToken.decimals);
    try {
      store.setSwapButtonLoading(true);
      let response: ISimulateSwapResponse;
      let quoteInputValue: number;
      if (store.swap.straightDirection) {
        response = await simulateSwap(store.swap.baseToken.address, quoteToken.address, valueInUnits)
        quoteInputValue = parseFloat(response.askUnits) / 10**quoteToken.decimals
      } else {
        response = await simulateReverseSwap(quoteToken.address, store.swap.baseToken.address, valueInUnits)
        quoteInputValue = parseFloat(response.offerUnits) / 10**quoteToken.decimals
      }
  
      store.setSimulatedSwap(response);
      store.setSwapInputValue(SWAP_TOKEN_TYPE.QUOTE, quoteInputValue)
    } catch (error) {
      Logger.error(error);
    } finally {
      store.setSwapButtonLoading(false);
    }

  }, [
       quoteToken.address,
       quoteToken.decimals,
       simulateReverseSwap,
       simulateSwap,
       store
     ])

  const quoteTokenValueChanged = useCallback(async (value: number | string | undefined) => {
    if (!value) {
      store.setSimulatedSwap(null)
      store.setSwapInputValue(SWAP_TOKEN_TYPE.BASE, undefined)
      store.setSwapInputValue(SWAP_TOKEN_TYPE.QUOTE, undefined)
      return
    }
    const valueInUnits = calculateUnits(value, quoteToken.decimals);
    try {
      let response: ISimulateSwapResponse;
      let baseInputValue: number;
      store.setSwapButtonLoading(true);
      if (store.swap.straightDirection) {
        response = await simulateReverseSwap(store.swap.baseToken.address, quoteToken.address, valueInUnits)
        baseInputValue = parseFloat(response.offerUnits) / 10**store.swap.baseToken.decimals
      } else {
        response = await simulateSwap(quoteToken.address, store.swap.baseToken.address, valueInUnits)
        baseInputValue = parseFloat(response.askUnits) / 10**store.swap.baseToken.decimals
      }
      store.setSimulatedSwap(response);
      store.setSwapInputValue(SWAP_TOKEN_TYPE.BASE, baseInputValue)
    } catch (error) {
      if ((error as any)?.response?._data?.includes('Insufficient amount')) {
        store.setSwapTransactionError('Insufficient amount of the asset in the pool. Consider entering a lower value.')
      }
      Logger.error(error);
    } finally {
      store.setSwapButtonLoading(false);
    }

  }, [
      quoteToken.address,
      quoteToken.decimals,
      simulateReverseSwap,
      simulateSwap,
      store
    ])

  const switchTokens = useCallback( async () => {
    const oldDirection = store.swap.straightDirection
    if (!store.swap.quoteToken) {
      return;
    }

    if (!store.swap.simulatedSwap) {
      store.changeSwapDirection()
      return;
    }

    try {
      store.setSwapButtonLoading(true);
      let response;
      if (oldDirection) { // changing to QUOTE -> BASE
        store.setSwapInputValue(SWAP_TOKEN_TYPE.BASE, undefined);
        const valueInUnits = calculateUnits(store.swap.quoteTokenInputValue, quoteToken.decimals);
        response = await simulateSwap(store.swap.quoteToken.address, store.swap.baseToken.address, valueInUnits);
        const baseInputValue = parseFloat(response.askUnits) / 10**store.swap.baseToken.decimals
        store.setSwapInputValue(SWAP_TOKEN_TYPE.BASE, baseInputValue);
      } else {
        const valueInUnits = calculateUnits(store.swap.baseTokenInputValue, store.swap.baseToken.decimals);
        response = await simulateSwap(store.swap.baseToken.address, store.swap.quoteToken.address, valueInUnits)
        const quoteInputValue = parseFloat(response.askUnits) / 10**store.swap.quoteToken.decimals
        store.setSwapInputValue(SWAP_TOKEN_TYPE.QUOTE, quoteInputValue);
      }

      store.changeSwapDirection()
      store.setSimulatedSwap(response);
    } catch (e) {
      Logger.error(e);
    } finally {
      store.setSwapButtonLoading(false);
    }
  }, [store, quoteTokenValueChanged, baseTokenValueChanged])  

  if (!quoteToken) {
    return null;
  }

  return (
    <Box className="exchanger">
      <SwapHeader />
      <Box sx={{ flexDirection: store.swap.straightDirection ? 'column' : 'column-reverse', display: 'flex' }} className="exchanger--container">
        <ExchangeTokenBlock token={store.swap.baseToken} tokenType={SWAP_TOKEN_TYPE.BASE} onChange={baseTokenValueChanged}/>
        <hr />
        <ExchangeTokenBlock token={quoteToken} tokenType={SWAP_TOKEN_TYPE.QUOTE} onChange={quoteTokenValueChanged}/>
        <div className='switch-button' onClick={switchTokens}>
          <UpsideDownIcon />
        </div>
      </Box>
      <SwapInformation />
      <Box>
        <ExchangeButton />
      </Box>
    </Box>
  );
})