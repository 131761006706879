import { round } from 'lodash';
import { ReactComponent as PriceTriangleDown } from '../../assets/images/price-triangle-down.svg';
import { ReactComponent as PriceTriangleUp } from '../../assets/images/price-triangle-up.svg';
import './styles.scss';

interface IProps {
  value?: string | number;
  large?: boolean;
}

type Direction = 'positive' | 'negative'

const PriceChange = ({ value, large }: IProps) => {
  if (value === undefined) {
    return null;
  }
  const valueFloat= round(Number(value), 2);
  const direction: Direction = valueFloat >= 0 ? 'positive' : 'negative';
  const largeClass = large ? 'large' : ''

  return (
    <div className={`price-change ${direction} ${largeClass}`}>
      <div className="price-change--icon">
        { direction === 'positive' ? <PriceTriangleUp /> : <PriceTriangleDown />}
      </div>
      <div className="price-change--value">{ direction === 'positive' && '+'}{valueFloat}%</div>
    </div>
  )
};

export default PriceChange;
