import { min } from 'lodash';
import { GAME_POINTS_REWARD_PER_SECOND, GAME_TIMESPAN_LENGTH } from "../constants";

export const gameSecondsLeft = (startedAt: Date | null): number | null => {
  if (!startedAt) {
    return null;
  }
  const startedAtMs = new Date(startedAt).getTime();
  const nowMs = new Date().getTime();

  const calculatedSecondsLeft = startedAtMs + GAME_TIMESPAN_LENGTH - nowMs;

  if (calculatedSecondsLeft < 0) {
    // last game has been finished
    return null;
  } else {
    // game is in progress
    return calculatedSecondsLeft / 1000;
  }
}

export const recalculatePoints = (
  points: number,
  startedAt: Date | null,
  calculatedAt: Date | null
): number => {
  if (!startedAt || !calculatedAt) {
    return points;
  }

  const calculatedAtMs = new Date(calculatedAt).getTime()
  const startedAtMs = new Date(startedAt).getTime()

  if (calculatedAtMs > startedAtMs + GAME_TIMESPAN_LENGTH) {
    return points;
  }

  const now = new Date();
  const toTimeMs: number = min([now.getTime(), startedAtMs + GAME_TIMESPAN_LENGTH]) as number;

  const rewardableTimeInSeconds: number = (toTimeMs - calculatedAtMs) / 1000;
  return points + rewardableTimeInSeconds * GAME_POINTS_REWARD_PER_SECOND;
}