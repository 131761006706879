import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { ReactComponent as FiltersIcon } from '../../assets/images/filters.svg';
import { BLANK_APP_FILTERS } from '../../constants';
import { IPromotedToken } from '../../interfaces/promotedToken';
import { AppStoreContext } from '../../store';
import { onScroll } from '../../utils/filersScroll';
import WindowComponent from '../WindowComponent';
import './AppFilters.scss';
import FiltersComponent from './FiltersComponent';
import HotButtons from './HotButtons';
import SearchComponent from './SearchComponent';

interface IProps {
  promotedToken: IPromotedToken | null;
}

export const AppFilters = observer(({ promotedToken }: IProps) => {
  const store = useContext(AppStoreContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorPop, setAnchorPop] = useState<HTMLButtonElement | null>(null);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [filtersMutated, setFiltersMutated] = useState<boolean>(false);

  useDeepCompareEffect(() => {
    setFiltersMutated(!isEqual(store.appFilters, BLANK_APP_FILTERS));
  }, [store.appFilters]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const slashFunction = useCallback((event: any) => {
    if (event.key === '/') {
      let search = document.getElementById('search-element');
      if (search) {
        search.click();
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', slashFunction);
    return () => {
      document.removeEventListener('keydown', slashFunction);
    };
  }, [slashFunction]);

  const handleClose = () => {
    setAnchorPop(null);
  };

  return (
    <Box
      id="filters-block"
      sx={{
        overflowY: Boolean(anchorEl) ? 'visible' : 'hidden',
        overflowX: Boolean(anchorEl) ? 'visible' : 'auto'
      }}>
      <HotButtons />
      <Box className="filters-right">
        <SearchComponent
          open={openSearch}
          onOpenClick={(value) => {
            setOpenSearch(value);
          }}
          promotedToken={promotedToken}
        />

        {!openSearch && (
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Button
              className="filters-button"
              variant="contained"
              startIcon={<FiltersIcon width={18} height={18} />}
              onClick={(event) => {
                setOpenFilters(!openFilters);
              }}>
              Filters
              {filtersMutated && <span className="filters-dot" />}
            </Button>
          </Box>
        )}

        {!openSearch && (
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <Button
              className="filters-button"
              variant="contained"
              startIcon={<FiltersIcon width={18} height={18} />}
              onClick={(event) => {
                setAnchorPop(event.currentTarget);
              }}>
              Filters
              {filtersMutated && <span className="filters-dot" />}
            </Button>
          </Box>
        )}
      </Box>
      <Popover
        open={Boolean(anchorPop)}
        anchorEl={anchorPop}
        className="filters-popover"
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            className: 'filters-popover-paper',
            elevation: 0
          }
        }}>
        <>
          <Box mb={3} className="filters-label">
            Filters
            <Box height={24} onClick={handleClose}>
              <CloseIcon
                sx={{
                  width: '18px',
                  height: '18px'
                }}
              />
            </Box>
          </Box>
          <Box className="filters-component-wrapper">
            <FiltersComponent
              onClose={() => {
                setOpenFilters(false);
                setAnchorPop(null);
              }}
            />
          </Box>
        </>
      </Popover>

      <WindowComponent
        title="Filters"
        open={openFilters}
        onClose={() => {
          setOpenFilters(false);
        }}
        windowContent={
          <FiltersComponent
            onClose={() => {
              setOpenFilters(false);
              setAnchorPop(null);
            }}
          />
        }
      />
    </Box>
  );
});
