function numberToSubscript(num: number): string {
  if (num < 0 || num > 99 || !Number.isInteger(num)) {
    throw new Error('Input must be an integer between 0 and 99');
  }

  let numStr = num.toString();

  const subscriptMap: string[] = [
    '₀',
    '₁',
    '₂',
    '₃',
    '₄',
    '₅',
    '₆',
    '₇',
    '₈',
    '₉'
  ];

  let subscriptDigits = '';
  for (let i = 0; i < numStr.length; i++) {
    const digit = parseInt(numStr[i], 10);
    subscriptDigits += subscriptMap[digit];
  }

  return subscriptDigits;
}

export const transformNumber = (num: number): any => {
  let strNum = num.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 12
  });

  if (strNum.startsWith('0.') && strNum.slice(2).startsWith('000')) {
    let nonZeroIndex = 2;
    let zeroCount = 0;

    for (let i = 2; i < strNum.length; i++) {
      if (strNum[i] === '0') {
        zeroCount++;
      } else {
        nonZeroIndex = i;
        break;
      }
    }

    if (zeroCount > 0) {
      return `0.0${numberToSubscript(zeroCount)}${strNum.slice(nonZeroIndex, 10)}`;
    }
  }

  return Number(strNum).toFixed(5);
};
