import amplitude from 'amplitude-js';
import Logger from './logger';

const AMPLITUDE_KEY = process.env.REACT_APP_AMPLITUDE_KEY as string;

class AmplitudeIntegration {
  userId: string | null = null;

  isEnabled: boolean = false;
  isInitialized: boolean = false;


  constructor() {
    Logger.log('[Amplitude] Initializing Amplitude...');
    if (!AMPLITUDE_KEY) {
      Logger.warn('[Amplitude] AMPLITUDE_KEY key is empty, skip initializing Amplitude.');
      return;
    }

    if (window.location.href.includes('tonbubbles.xyz')) {
      Logger.info('[Amplitude] Enabling Amplitude.');
      this.isEnabled = true;
    }

    if (this.isEnabled) {
      amplitude.getInstance().init(AMPLITUDE_KEY, undefined, {
        logLevel: 'INFO',
        onError: this.onError
      });
      this.isInitialized = true
    } else {
      Logger.info('[Amplitude] Skip initializing ReactGA4 (domain does not include "tonbubbles.xyz")')
    }
  }

  onError() {
    Logger.error(`[Amplitude] Error occured`)
  }

  event(name: string, properties: any = {}) {
    if (this.isEnabled && this.isInitialized) {
      Logger.info('[Amplitude] Sending event', name, properties);
      amplitude.getInstance().logEvent(name, properties);
    } else {
      Logger.info('[Amplitude] Not sending an event because amplitude is not initialized/enabled.')
    }
  }

  setUserData(address: string | null): void {
    if (!this.isEnabled || !this.isInitialized) return
    if (address) {
      Logger.info('[Amplitude] Assigning user data...', address)
    } else {
      Logger.log('[Amplitude] Clearing user data.')
    }
    this.userId = address
    amplitude.getInstance().setUserId(this.userId);
  }
}

const amplitudeInstance = new AmplitudeIntegration()
export default amplitudeInstance
