import ReactGA from 'react-ga4';
import Logger from './logger';

interface IGaUser {
  id?: string | null; // address
}

const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GA_KEY as string;

class BubblesGa {
  user: IGaUser = {
    id: null
  }

  isEnabled: boolean = false;
  isInitialized: boolean = false;


  constructor() {
    Logger.log('[ReactGa4] Initializing ReactGa4...');
    if (!GOOGLE_ANALYTICS_KEY) {
      Logger.warn('[ReactGa4] GA key is empty, skip initializing GA.');
      return;
    }

    if (window.location.href.includes('tonbubbles.xyz')) {
      Logger.info('[ReactGa4] Enabling ReactGa4.');
      this.isEnabled = true;
    }

    if (this.isEnabled) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
      this._setToGa({ anonymizeIp: true })
      this.isInitialized = true
    } else {
      Logger.info('[ReactGa4] Skip initializing ReactGA4 (domain does not include "tonbubbles.xyz")')
    }
  }

  event(eventName: string, eventParams: any = {}) {
    if (this.isEnabled && this.isInitialized) {
      Logger.info('[ReactGa4] Sending event to GA', eventName, eventParams)
      ReactGA.event(eventName, eventParams);
    }
  }

  pageView(page: string = '/', title: string = 'Main page') {
    if (this.isEnabled && this.isInitialized) {
      ReactGA.send({ hitType: 'pageview', page, title })
    }
  }

  public modalView(modalName: string, params: any = {}): void {
    if (this.isEnabled && this.isInitialized) {
      this.event('screen_view', {
        screen_name: modalName,
        ...params
      })
    }
  }

  setUserData(address: string | null): void {
    if (!this.isEnabled || !this.isInitialized) return
    if (address) {
      Logger.info('[ReactGa4] Assigning user data to TonBubbles GA:', address)
    } else {
      Logger.log('[ReactGa4] Clearing user data to TonBubbles GA.')
    }
    this.user.id = address
    this._setToGa({ user_id: this.user.id })
  }

  private _setToGa(object: any): void {
    if (this.isInitialized) {
      ReactGA.set(object)
    }
  }
}

const googleAnalytics = new BubblesGa()
export default googleAnalytics
