import { AmountType } from '@ston-fi/sdk';
import missing from '../assets/images/missing.png';
import {
  API_BASE_URL,
  SWAP_OPERATION_TITLE,
  SWAP_TOKEN_TYPE
} from '../constants';
import { IToken } from '../interfaces/token';

export const sortTokens = (
  tokens: IToken[],
  sortedBy: string,
  direction: string
): IToken[] =>
  tokens.sort((a: any, b: any) => {
    const keys = sortedBy.split('.');
    const start = keys.length > 1 ? a[keys[0]][keys[1]] : a[keys[0]];
    const end = keys.length > 1 ? b[keys[0]][keys[1]] : b[keys[0]];
    if (sortedBy === 'poolCreatedAt') {
      return direction === 'desc' ? (new Date(end).getTime() - new Date(start).getTime()) : (new Date(start).getTime() - new Date(end).getTime())
    } else {
      return direction === 'desc' ? end - start : start - end;
    }
  });

export const calculateAmountUnits = (
  value: number | string,
  decimals: number
): AmountType => parseFloat(value as string) * 10 ** decimals;

export const unitsToDisplayedValue = (
  value: number | string | null,
  decimals: number
): number => {
  if (!value) {
    return 0;
  }

  return parseFloat(value as string) / 10 ** decimals;
};

export const swapOperationTitle = (
  straightDirection: boolean,
  tokenType: SWAP_TOKEN_TYPE
): SWAP_OPERATION_TITLE => {
  if (
    (tokenType === SWAP_TOKEN_TYPE.BASE && straightDirection) ||
    (tokenType === SWAP_TOKEN_TYPE.QUOTE && !straightDirection)
  ) {
    return SWAP_OPERATION_TITLE.SELL;
  } else {
    return SWAP_OPERATION_TITLE.BUY;
  }
};

export const tokenImageURL = (token: IToken): string => {
  const imagePath = token?.imagePath;

  if (imagePath) {
    if (imagePath.startsWith('http')) {
      return imagePath;
    } else {
      return `${API_BASE_URL}${imagePath}`;
    }
  } else {
    return missing;
  }
};
