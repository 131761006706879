import { default as avatar1 } from './avatars/1.png';
import { default as avatar2 } from './avatars/2.png';
import { default as avatar3 } from './avatars/3.png';
import { default as avatar4 } from './avatars/4.png';
import { default as avatar5 } from './avatars/5.png';
import { default as avatar6 } from './avatars/6.png';
import { default as avatar7 } from './avatars/7.png';
import { default as avatar8 } from './avatars/8.png';
import { default as avatar9 } from './avatars/9.png';

export const avatars = {
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9
};
