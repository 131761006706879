import { shuffle } from 'lodash';
import { observer } from 'mobx-react';
import { useContext } from 'react';
import { IPromotedToken } from '../../interfaces/promotedToken';
import { AppStoreContext } from '../../store';
import TrendingToken from '../TrendingToken';
import './styles.scss';

const TrendingTokensPanel = observer(() => {
  const store = useContext(AppStoreContext);
  const promotedTokens = store.promotedTokens?.promotedTokens;

  if (!promotedTokens) {
    return null;
  }

  const promotedTokensSet: IPromotedToken[] = shuffle(promotedTokens).slice(0, 4);
 
  return (
    <div className="trending-tokens">
      <h3 className="trending-tokens--header">Trending</h3>
      <div className="trending-tokens--container">
        {promotedTokensSet.map((token) => (
          <TrendingToken token={token} />
        ))}
      </div>
    </div>
  );
});

export default TrendingTokensPanel;
