import { unauthorizedRequest } from ".";
import { ChartType, Period } from "../interfaces/chart";
import { IToken } from "../interfaces/token";

const fetchChartData = async (poolAddress: string) => {
  const response = await unauthorizedRequest(`tokens/${poolAddress}/chart`);

  return {
    records: response.data.records,
    timeframe: response.data.timeframe,
  };
};

const fetchTokens = async (): Promise<IToken[]> => {
  const response = await unauthorizedRequest('public/tokens.json');

  if (!response.success) {
    throw new Error(`Error fetching tokens info.`);
  }

  const tokens: IToken[] = response.data.tokens;
  return tokens;
};

export const fetchChart = async(quoteTokenAddress: string, chartType: ChartType, period: Period): Promise<any> => {
  const response = await unauthorizedRequest(`charts/${quoteTokenAddress}/${chartType}?period=${period}`);
  return response.data;
}

export { fetchChartData, fetchTokens };
