import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Toolbar from '@mui/material/Toolbar';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/TonBubbles.svg';
import handsup from '../../assets/images/handsup.png';
import useSetTrackingUser from '../../hooks/useSetTrackingUser';
import { AuthButton } from '../AuthButton/AuthButton';
import AvatarMenu from './AvatarMenu';
import './Header.scss';

export const Header = observer(() => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  useSetTrackingUser();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const navigateToMain = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <AppBar className="header-component">
      <Box className="header-wrapper">
        <Toolbar disableGutters>
          <Box
            onClick={navigateToMain}
            sx={{
              flexGrow: 4,
              textAlign: 'left',
              cursor: 'pointer',
              marginTop: '6px',
              display: { xs: 'none', md: 'flex' }
            }}>
            <img src={logo} alt="logo" />
          </Box>
          <Box
            onClick={navigateToMain}
            sx={{
              flexGrow: 3,
              textAlign: 'left',
              cursor: 'pointer',
              marginTop: '16px',
              display: { md: 'none' }
            }}>
            <img src={logo} width="112px" alt="logo" />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              marginTop: '6px',
              justifyContent: 'flex-end'
            }}>
            <AuthButton onClick={handleClick} isActive={Boolean(anchorEl)} />
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              className="earn-popover"
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              slotProps={{
                paper: {
                  sx: {
                    borderRadius: '12px !important',
                    marginTop: '8px',
                    padding: '16px 16px 0px 16px',
                    width: '367px',
                    height: '320px',
                    boxShadow: '0px 6px 12px 0px #D1D3D3'
                  }
                }
              }}>
              <Box
                mb={1}
                sx={{
                  display: { xs: 'flex', md: 'none' },
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}>
                <CloseIcon
                  onClick={handleClose}
                  sx={{
                    height: '28px'
                  }}
                />
              </Box>
              <Box
                sx={{
                  color: 'black',
                  fontSize: '18px',
                  fontWeight: 580
                }}
                mb={1}>
                Earn points for $Addickted airdrop!
              </Box>
              <Box className="earn-wrapper">
                <Box mb={1}>
                  Earn points by using TonBubbles and get a FREE{' '}
                  <strong>$DICK</strong> airdrop!
                </Box>
                <Box mb={1}>
                  Find a farm bubble and click on it to increase your{' '}
                  <strong>$DICK </strong>
                  rewards. Start farming every day to collect even more points!
                </Box>
                <Box>
                  P.S. The counter resets every 6 hours, so you can get back and
                  farm it again!
                </Box>
                <img src={handsup} width="321" alt="logo" />
              </Box>
            </Popover>
          </Box>
          <AvatarMenu />
        </Toolbar>
      </Box>
    </AppBar>
  );
});
