import { authorizedRequest } from '.';
import { HTTP_METHOD } from '../interfaces/common';
import { IUser } from '../interfaces/user';

const fetchCurrentUser = async (): Promise<IUser | null> => {
  const response = await authorizedRequest('users/me');
  if (!response) {
    return null;
  }
  const currentUser: IUser = response.data;
  return currentUser;
};

const startGame = async (): Promise<IUser | null> => {
  const response = await authorizedRequest(
    '/users/start-game',
    HTTP_METHOD.PATCH
  );
  if (!response) {
    return null;
  }
  const currentUser: IUser = response.data;
  return currentUser;
};

const toggleWatchlist = async (token: string): Promise<IUser | null> => {
  const response = await authorizedRequest(
    '/users/tokens-watchlist',
    HTTP_METHOD.PATCH,
    JSON.stringify({
      tokenAddress: token
    })
  );
  if (!response) {
    return null;
  }
  const currentUser: IUser = response.data;
  return currentUser;
};

export { fetchCurrentUser, startGame, toggleWatchlist };
