import { makeAutoObservable, runInAction } from 'mobx';
import { createContext } from 'react';
import {
  DEFAULT_SLIPPAGE_TOLERANCE,
  DisplayTokensFilterValue,
  FILTERS_DEFAULT_DISPLAYTOKENS,
  INITIAL_APP_FILTERS,
  REPRESENTATION_DEFAULT_PRICE_MCAP,
  REPRESENTATION_DEFAULT_TIMEFRAME,
  RepresentationTimeframe,
  RepresentationValue,
  SWAP_TOKEN_TYPE,
  TON_CONTRACT_ADDRESS,
  TON_CONTRACT_DECIMALS,
  TON_LOGO_PATH
} from './constants';
import { ChartType, Period } from './interfaces/chart';
import { BubblesSort, IAppFilters, ITableSortState } from './interfaces/filters';
import { IPromotedTokensResponse } from './interfaces/promotedToken';
import { ISimulateSwapResponse } from './interfaces/stonFi';
import { ISwapToken, IToken } from './interfaces/token';
import { IUser } from './interfaces/user';
import amplitudeInstance from './utils/amplitude';
import bubblesGa from './utils/bubblesGa';
import Logger from './utils/logger';

const initialSwapInformation = {
  straightDirection: true,
  slippageTolerance: DEFAULT_SLIPPAGE_TOLERANCE,
  baseToken: {
    symbol: 'TON',
    address: TON_CONTRACT_ADDRESS,
    decimals: TON_CONTRACT_DECIMALS,
    imagePath: TON_LOGO_PATH
  },
  quoteToken: null,
  simulatedSwap: null,
  baseTokenBalance: null,
  quoteTokenBalance: null,
  baseTokenPriceUsd: null,
  quoteTokenPriceUsd: null,
  buttonLoading: false,
  baseTokenInputValue: undefined,
  quoteTokenInputValue: undefined,
  transactionError: null
};

interface ChartInformation {
  chartType: ChartType;
  period: Period;
}

class AppData {
  currentUser: IUser | null;
  allTokens: IToken[];
  promotedTokens: IPromotedTokensResponse | null;
  startGameCalled: boolean;
  isCanvasRendered: boolean;
  selectedBubbleCode: string | null;
  isWalletConnected: boolean;
  swap: {
    straightDirection: boolean; // BASE token swapping to QUOTE token
    slippageTolerance: number;
    baseToken: ISwapToken;
    quoteToken: ISwapToken | null;
    simulatedSwap: ISimulateSwapResponse | null;
    baseTokenBalance: number | null;
    quoteTokenBalance: number | null;
    baseTokenPriceUsd: number | null;
    quoteTokenPriceUsd: number | null;
    buttonLoading: boolean;
    baseTokenInputValue?: number;
    quoteTokenInputValue?: number;
    transactionError: string | null;
  };
  filters: {
    representation: RepresentationValue;
    representationTimeframe: RepresentationTimeframe;
    displayTokens: DisplayTokensFilterValue;
    bubblesSort: BubblesSort
  };
  chart: ChartInformation;
  tableSort: ITableSortState;
  appFilters: IAppFilters;
  scrollOffset: number

  constructor() {
    makeAutoObservable(this);
    this.currentUser = null;
    this.allTokens = [];
    this.promotedTokens = null;
    this.startGameCalled = false;
    this.isCanvasRendered = false;
    this.selectedBubbleCode = null;
    this.isWalletConnected = false;
    this.swap = initialSwapInformation;
    this.scrollOffset = 0;
    this.filters = {
      representation: REPRESENTATION_DEFAULT_PRICE_MCAP,
      representationTimeframe: REPRESENTATION_DEFAULT_TIMEFRAME,
      displayTokens: FILTERS_DEFAULT_DISPLAYTOKENS,
      bubblesSort: 'default'
    };
    this.chart = {
      chartType: 'price',
      period: 'w1'
    }
    this.appFilters = INITIAL_APP_FILTERS;
    this.tableSort = {
      by: 'liquidity',
      direction: 'desc'
    }
  }

  setScrollOffset(value: number) {
    this.scrollOffset = value;
  }

  changeSwapDirection() {
    this.swap.straightDirection = !this.swap.straightDirection;
  }

  setSimulatedSwap(swapInformation: ISimulateSwapResponse | null) {
    runInAction(() => {
      this.swap.simulatedSwap = swapInformation;
    });
  }

  setChart(chart: ChartInformation) {
    if (chart.chartType === 'marketCaps') {
      chart.period = 'w1';
    }
    this.chart = chart;
  }

  setSwapInputValue(tokenType: SWAP_TOKEN_TYPE, value?: number) {
    tokenType === SWAP_TOKEN_TYPE.BASE
      ? (this.swap.baseTokenInputValue = value)
      : (this.swap.quoteTokenInputValue = value);
  }

  setSwapQuoteToken(token: ISwapToken) {
    runInAction(() => {
      this.swap.quoteToken = token;
    });
  }

  resetSwap() {
    runInAction(() => {
      this.swap = initialSwapInformation;
    });
  }

  setActualPrice(price: number | null, tokenType: SWAP_TOKEN_TYPE) {
    tokenType === SWAP_TOKEN_TYPE.BASE
      ? (this.swap.baseTokenPriceUsd = price)
      : (this.swap.quoteTokenPriceUsd = price);
  }

  setTokenBalance(balance: number | null, tokenType: SWAP_TOKEN_TYPE) {
    tokenType === SWAP_TOKEN_TYPE.BASE
      ? (this.swap.baseTokenBalance = balance)
      : (this.swap.quoteTokenBalance = balance);
  }

  setSwapTransactionError(value: string | null) {
    this.swap.transactionError = value;
  }

  setSwapButtonLoading(value: boolean = true) {
    this.swap.buttonLoading = value;
  }

  setSwapSlippageTolerance(tolerance: number) {
    if (tolerance < 0.01 || tolerance > 0.5) {
      Logger.warn('Incorrect slippage tolerance', tolerance);
      return;
    }

    this.swap.slippageTolerance = tolerance;
  }

  setTableSortState(value: ITableSortState) {
    this.tableSort = value;
  }
  

  setWalletConnected(value: boolean = true) {
    this.isWalletConnected = value;
  }

  setSelectedBubbleCode(code: string | null) {
    this.selectedBubbleCode = code;
  }

  canvasRendered(status: boolean = true) {
    this.isCanvasRendered = status;
  }

  startGame(called: boolean = true) {
    if (called) {
      bubblesGa.event('start_game');
      amplitudeInstance.event('startGame')
    }
    this.startGameCalled = called;
  }

  setCurrentUser(user: IUser | null) {
    this.currentUser = user;
  }

  setAppFilters(value: IAppFilters) {
    this.appFilters = value;
  }

  setBubblesSort(value: BubblesSort) {
    this.filters.bubblesSort = value;
  }

  setRepresenation(value: RepresentationValue) {
    this.filters.representation = value;
  }

  setDisplayFilter(value: DisplayTokensFilterValue) {
    this.filters.displayTokens = value;
  }

  setRepresentationTimeframe(value: RepresentationTimeframe) {
    this.filters.representationTimeframe = value;
  }

  setAllTokens(value: IToken[]) {
    this.allTokens = value;
  }

  setPromotedTokens(value: IPromotedTokensResponse | null) {
    this.promotedTokens = value;
  }
}

export const appStore = new AppData();
export const AppStoreContext = createContext(appStore);
