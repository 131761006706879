export interface ObjectLiteral<T> {
  [key: string]: T;
}

export enum HTTP_METHOD {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export interface IFetchOptions {
  method: HTTP_METHOD;
  body?: XMLHttpRequestBodyInit;
  headers?: HeadersInit;
}