import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { round } from 'lodash';
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ISwapToken } from "../../interfaces/token";
import { AppStoreContext } from "../../store";
import { compactSmall, numberAsDigitNotation } from '../../utils';
import Information from '../Information/Information';

export const SwapInformation = observer(() => {
  const store = useContext(AppStoreContext);
  const [showInformation, setShowInformation] = useState<boolean>(false);
  const [expandedInformation, setExpandedInformation] = useState<boolean>(false);
  const offerToken: ISwapToken | null = store.swap.straightDirection ? store.swap.baseToken : store.swap.quoteToken;
  const askToken: ISwapToken | null = store.swap.straightDirection ? store.swap.quoteToken : store.swap.baseToken;
  
  useEffect(() => {
    if (!store.swap.simulatedSwap?.offerUnits || store.swap.simulatedSwap?.offerUnits === '0') {
      setShowInformation(false);
    } else {
      setShowInformation(true);
    }

  }, [store.swap.simulatedSwap])

  const priceUsd = useMemo(() => {
    const value = store.swap.straightDirection
      ? store.swap.baseTokenPriceUsd
      : store.swap.quoteTokenPriceUsd
    return value;    
  }, [store.swap.baseTokenPriceUsd, store.swap.quoteTokenPriceUsd, store.swap.straightDirection])

  const revealInformation = useCallback(() => {
    setExpandedInformation(!expandedInformation);
  }, [expandedInformation])

  if (!showInformation || !store.swap.simulatedSwap) {
    return null;
  }

  if (!offerToken || !askToken) {
    return null;
  }

  const informationContent = () => {
    const swapInformation = store.swap.simulatedSwap;
    if (!expandedInformation || !swapInformation) {
      return null
    }

    const formattedAtLeast = numberAsDigitNotation(
      compactSmall(parseInt(swapInformation.minAskUnits) / 10**askToken.decimals)
    )

    const formattedFee = numberAsDigitNotation(
      compactSmall(parseInt(swapInformation.feeUnits) / 10**askToken.decimals)
    )

    return (
      <>
        <div className="swapDetails--content--item">
          <div className="item-title">Max slippage
            <Information value="Your transaction will not be executed if the price changes unfavorably beyond 1%"/>
          </div>
          <div className="item-value">{swapInformation.slippageTolerance}%</div>
        </div>
        <div className="swapDetails--content--item">
          <div className="item-title">Receive at least</div>
          <div className="item-value">{formattedAtLeast} ${askToken.symbol}</div>
        </div>
        <div className="swapDetails--content--item">
          <div className="item-title">Fee ({parseFloat(swapInformation.feePercent).toFixed(3)}%)</div>
          <div className="item-value">{formattedFee} ${askToken.symbol}</div>
        </div>
        <div className="swapDetails--content--item">
          <div className="item-title">Network cost</div>
          <div className="item-value">0.015-0.265 TON</div>
        </div>
      </>
    )
  }

  const formattedSwapRate: string = numberAsDigitNotation(
    compactSmall(Number(store.swap.simulatedSwap.swapRate))
  )

  return (
    <div className="swapDetails">
      <div className="swapDetails--header">
        <div className="swapDetails--header--content">
          1 {offerToken.symbol} ≈ {formattedSwapRate} {askToken.symbol}
          <span className="price">(${round(priceUsd || 0, 6)})</span>
        </div>
        <div className="swapDetails--header--handler" onClick={revealInformation}>
          <KeyboardArrowDownIcon className={expandedInformation ? 'expanded' : ''}/>
        </div>
      </div>
      <div className={`swapDetails--content ${showInformation ? 'show' : ''}`}>
        {informationContent()}
      </div>
    </div>
  )
})
