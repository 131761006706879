import { StonApiClient } from '@ston-fi/api';
import { useCallback } from 'react';
import { IAsset } from '../interfaces/stonFi';

const stonApiClient = new StonApiClient();

const useGetActualPrice = () => {
  return useCallback(
    async (address: string): Promise<number> => {
      const response: IAsset = await stonApiClient.getAsset(address);
      return Number(response.dexPriceUsd)
  }, [])
};

export default useGetActualPrice;