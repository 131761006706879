import { useContext, useEffect } from 'react';
import { AppStoreContext } from '../store';
import amplitudeInstance from '../utils/amplitude';
import googleAnalytics from '../utils/bubblesGa';

function useSetTrackingUser() {
  const store = useContext(AppStoreContext);

  useEffect(() => {
    const address = store.currentUser?.address || null
    googleAnalytics.setUserData(address);
    amplitudeInstance.setUserData(address);
  }, [store.currentUser?.address])
}

export default useSetTrackingUser
