import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    let prefix = '$';
    let suffix = '';
    if (props.name === 'Price change') {
      prefix = '';
      suffix = '%';
    }
    if (props.name === 'Pair age') {
      prefix = '';
      suffix = 'H';
    }

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix={prefix}
        suffix={suffix}
      />
    );
  }
);

interface IProps {
  label: string;
  type: string;
  filter: string;
  min: number;
  max: number;
  remark?: string;
  onChange: (key: string, value: string, type: string) => void;
}

const FilterInputPair = observer(
  ({ label, type, filter, min, max, onChange, remark }: IProps) => {
    const renderRemark = () => {
      if (!remark) return null;
      return <Box className="filter-input-pair--remark">{remark}</Box>;
    };

    return (
      <Box className="filter-input-pair">
        <Box
          className="filter-input-pair--title"
          sx={{ marginRight: 2, fontWeight: 600, fontSize: '14px' }}>
          <Box>{label || ''}</Box>
          {renderRemark()}
        </Box>
        <Box className="pair-wrapper">
          <TextField
            className="pair-field min"
            name={label}
            value={min || ''}
            placeholder={`Min ${type}`}
            InputProps={{
              inputComponent: NumericFormatCustom as any
            }}
            onChange={(e) => {
              onChange(filter, e.target.value, 'min');
            }}
          />
          to
          <TextField
            className="pair-field max"
            name={label}
            value={max || ''}
            placeholder={`Max ${type}`}
            InputProps={{
              inputComponent: NumericFormatCustom as any
            }}
            onChange={(e) => {
              onChange(filter, e.target.value, 'max');
            }}
          />
        </Box>
      </Box>
    );
  }
);

export default FilterInputPair;
