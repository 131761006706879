import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { observer } from 'mobx-react';
import { useContext, useState } from 'react';
import {
  BLANK_APP_FILTERS,
  MinMaxFilters,
  RepresentationTimeframe,
  RepresentationValue
} from '../../constants';
import { IAppFilters } from '../../interfaces/filters';
import { AppStoreContext } from '../../store';
import FilterInputPair from './FilterInputPair';

interface IProps {
  onClose: () => void;
}

const FiltersComponent = observer(({ onClose }: IProps) => {
  const store = useContext(AppStoreContext);
  const [timeframe, setTimeframe] = useState<RepresentationTimeframe>(
    store.filters.representationTimeframe
  );
  const [representation, setRepresentation] = useState<RepresentationValue>(
    store.filters.representation
  );
  const [filters, setFilters] = useState<IAppFilters>(store.appFilters);

  const handleTimeframe = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    setTimeframe(value as RepresentationTimeframe);
  };

  const handleRepresentation = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    setRepresentation(value as RepresentationValue);
  };

  const onChangeFilter = (key: string, value: string, type: string) => {
    setFilters((prev: any): IAppFilters => {
      return {
        ...prev,
        [key]: { ...prev[key], [type]: Number(value) || null }
      };
    });
  };

  const clearAppFilters = () => {
    setFilters(BLANK_APP_FILTERS);
  };

  const onApplyFilters = () => {
    store.setAppFilters(filters);
    store.setRepresenation(representation);
    store.setRepresentationTimeframe(timeframe);
    onClose();
    console.log('ooppa');
  };

  return (
    <Box className="filters-component">
      <Box>
        <Box className="filters-component-label">Table</Box>
        <Box mt={1}>
          {MinMaxFilters.map((item) => (
            <FilterInputPair
              key={item.filter}
              label={item.name}
              type={item.type}
              filter={item.filter}
              remark={item.remark}
              min={Number(filters[item.filter as keyof IAppFilters].min)}
              max={Number(filters[item.filter as keyof IAppFilters].max)}
              onChange={onChangeFilter}
            />
          ))}
        </Box>
        <Box>
          <Link className="clear-link" onClick={clearAppFilters}>
            Clear all
          </Link>
        </Box>
      </Box>
      <Box mt={3}>
        <Box mb={2} className="filters-component-label">
          Bubbles representation
        </Box>
        <Box mt={1}>
          <Box mb={1} className="small-label">
            Timeframe
          </Box>
          <ToggleButtonGroup
            className="filters-button-group"
            value={timeframe}
            exclusive
            onChange={handleTimeframe}
            fullWidth
            aria-label="timeframe">
            <ToggleButton value="h1">Hour</ToggleButton>
            <ToggleButton value="h24">Day</ToggleButton>
            <ToggleButton value="d7">Week</ToggleButton>
            <ToggleButton value="d30">Month</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box mt={2}>
          <Box mb={1} className="small-label">
            Bubble size representation
          </Box>
          <ToggleButtonGroup
            className="filters-button-group wide"
            value={representation}
            exclusive
            onChange={handleRepresentation}
            fullWidth
            aria-label="representation">
            <ToggleButton value="marketCap">M Cap</ToggleButton>
            <ToggleButton value="price">Price</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Box
        mt={2}
        className="apply-button-wrapper"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <button className="apply-button" onClick={onApplyFilters}>
          Apply filters
        </button>
      </Box>
    </Box>
  );
});

export default FiltersComponent;
