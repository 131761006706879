import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { ReactComponent as InformationIcon } from '../../assets/images/info-sign.svg';

import './styles.scss';

interface IProps {
  value: string;
}

const Information = ({ value }: IProps) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <>
          <button className="information-button" {...bindHover(popupState)}>
            <InformationIcon className="information-button" />
          </button>
          <HoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            className="default-popover">
            {value}
          </HoverPopover>
        </>
      )}
    </PopupState>
  );
  // const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // const open = Boolean(anchorEl);

  // const openInfo = useCallback((event: any) => {
  //   setAnchorEl(event.currentTarget);
  //   // const timeout = setTimeout(() => { setAnchorEl(null) }, 2000)
  //   // return () => {
  //   //   clearTimeout(timeout);
  //   // }
  // }, [])

  // const closeInfo = useCallback(() => {
  //   // setAnchorEl(null);
  // }, [])

  // return (
  //   <>
  //     <button className="information-button" onMouseEnter={openInfo}
  //       onMouseLeave={closeInfo}>
  //       <InformationIcon className="information-button" />
  //     </button>
  //     <Popover
  //       className="default-popover"
  //       open={open}
  //       anchorEl={anchorEl}
  //       anchorOrigin={{
  //         vertical: 'top',
  //         horizontal: 'center',
  //       }}
  //       anchorOrigin={{
  //         vertical: 'top',
  //         horizontal: 'center',
  //       }}
  //     >
  //       {value}
  //     </Popover>
  //   </>
  // )
};

export default Information;
