import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import './App.scss';
import Footer from './components/Footer';
import { Header } from './components/Header';
import Layout from './components/Layout';

const queryClient = new QueryClient();
const manifestURL = process.env.REACT_APP_TON_MANIFEST_URL;
const applicationUrl = process.env
  .REACT_APP_APPLICATION_URL as `${string}://${string}`;

function App() {
  return (
    <div className="App">
      <TonConnectUIProvider
        manifestUrl={manifestURL}
        uiPreferences={{ theme: THEME.DARK }}
        walletsListConfiguration={{
          includeWallets: [
            {
              appName: 'safepalwallet',
              name: 'SafePal',
              imageUrl:
                'https://s.pvcliping.com/web/public_image/SafePal_x288.png',
              tondns: '',
              aboutUrl: 'https://www.safepal.com',
              universalLink: 'https://link.safepal.io/ton-connect',
              deepLink: 'safepal-tc://',
              jsBridgeKey: 'safepalwallet',
              bridgeUrl: 'https://ton-bridge.safepal.com/tonbridge/v1/bridge',
              platforms: ['ios', 'android', 'chrome', 'firefox']
            },
            {
              appName: 'bitgetTonWallet',
              name: 'Bitget Wallet',
              imageUrl:
                'https://raw.githubusercontent.com/bitkeepwallet/download/main/logo/png/bitget%20wallet_logo_iOS.png',
              aboutUrl: 'https://web3.bitget.com',
              deepLink: 'bitkeep://',
              jsBridgeKey: 'bitgetTonWallet',
              bridgeUrl: 'https://bridge.tonapi.io/bridge',
              platforms: ['ios', 'android', 'chrome'],
              universalLink: 'https://bkcode.vip/ton-connect'
            },
            {
              appName: 'tonwallet',
              name: 'TON Wallet',
              imageUrl: 'https://wallet.ton.org/assets/ui/qr-logo.png',
              aboutUrl:
                'https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd',
              universalLink: 'https://wallet.ton.org/ton-connect',
              jsBridgeKey: 'tonwallet',
              bridgeUrl: 'https://bridge.tonapi.io/bridge',
              platforms: ['chrome', 'android']
            },
            {
              appName: 'openmask',
              name: 'OpenMask',
              imageUrl:
                'https://raw.githubusercontent.com/OpenProduct/openmask-extension/main/public/openmask-logo-288.png',
              aboutUrl: 'https://www.openmask.app/',
              jsBridgeKey: 'openmask',
              bridgeUrl: 'https://bridge.tonapi.io/bridge',
              platforms: ['chrome']
            }
          ]
        }}
        actionsConfiguration={{
          twaReturnUrl: applicationUrl
        }}>
        <QueryClientProvider client={queryClient}>
          <Header />
          <Layout />
          <Footer />
        </QueryClientProvider>
      </TonConnectUIProvider>
    </div>
  );
}

export default App;
