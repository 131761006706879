import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { IPromotedToken } from '../../interfaces/promotedToken';
import { promotedTokenImageURL } from '../../utils/promotedTokenUtils';
import { TokenTableRow } from './TokenTableRow';

interface IProps {
  row: IPromotedToken;
}

export const TableAdRow = ({ row }: IProps) => {
  const imageURL = promotedTokenImageURL(row);

  if (!row.listed || !row.token) {
    return (
      <TableRow key="not-listed-ad-token-row" className="ad-row">
        <TableCell
          sx={{
            position: 'sticky',
            left: 0
          }}></TableCell>
        <TableCell
          sx={{
            position: 'sticky',
            left: 0
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              width={30}
              height={30}
              src={imageURL}
              alt="logo"
              className="logo-image"
            />
            {row.symbol}
          </Box>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    );
  }

  return row.token ? (
    <TokenTableRow row={row.token} isAdRow={true} url={row.url} />
  ) : (
    <></>
  );
};
