import { round } from 'lodash';
import { observer } from "mobx-react";
import { useCallback, useContext, useEffect } from "react";
import { ReactComponent as WalletIcon } from '../../../assets/images/wallet.svg';
import { SWAP_TOKEN_TYPE } from "../../../constants";
import useJettonBalance from "../../../hooks/useJettonBalance";
import { ISwapToken } from "../../../interfaces/token";
import { AppStoreContext } from "../../../store";
import { unitsToDisplayedValue } from "../../../utils/tokenUtils";

interface IProps {
  token: ISwapToken
  tokenType: SWAP_TOKEN_TYPE
}

export const TokenBalance = observer((props: IProps) => {
  const store = useContext(AppStoreContext);
  const { token, tokenType } = props;
  const { getJettonBalance, getTonBalance } = useJettonBalance();

  const getBalance = useCallback( async() => {
    let tokenBalanceUnits: number | null = 0;

    if (tokenType === SWAP_TOKEN_TYPE.BASE) {
      tokenBalanceUnits = await getTonBalance();
    } else {
      tokenBalanceUnits = await getJettonBalance(token.address);
    }

    store.setTokenBalance(unitsToDisplayedValue(tokenBalanceUnits, token.decimals), tokenType)
  }, [tokenType, token.address, token.decimals, store])

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  if (!store.isWalletConnected) {
    return null
  }

  const displayedBalance = tokenType === SWAP_TOKEN_TYPE.BASE
  ? store.swap.baseTokenBalance
  : store.swap.quoteTokenBalance

  return (
    <div className='wallet-balance'>
      <WalletIcon/>
      <span>{round(displayedBalance || 0, 6).toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
    </div>
  )
})
