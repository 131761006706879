import { IAppFilters } from '../interfaces/filters';

// Import from process.env first
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const LOCAL_STORAGE_TON_TOKEN_KEY =
  process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY || 'bubbles-staging-jwt';
const reducedGameTimer = process.env.REACT_APP_REDUCED_GAME_TIMER;
export const TONCENTER_API_KEY = process.env.REACT_APP_TONCENTER_API_KEY;

// Table pagination
export const TOKENS_PER_PAGE = 20;

// TON transactional data
export const TON_CONTRACT_ADDRESS =
  'EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c';
export const TON_CONTRACT_DECIMALS = 9;
export const TON_REFERRAL_ADDRESS =
  'UQAgKOmUzkyFBjwwsEg3rz2B7o2VTrleGUCpnwynIpmY-0h2';
export const TON_LOGO_PATH = '/ton-logo.png';

// Swap

export const DEFAULT_SLIPPAGE_TOLERANCE = 0.01;
export enum SWAP_OPERATION_TITLE {
  SELL = 'You send',
  BUY = 'You receive'
}
export enum SWAP_TOKEN_TYPE {
  BASE = 'BASE',
  QUOTE = 'QUOTE'
}

export const BANNER_TOKENS_MIN_LIQUIDITY = 50_000;

// Bubble game
export const GAME_TIMESPAN_LENGTH = reducedGameTimer
  ? 3 * 60 * 1000
  : 6 * 1000 * 60 * 60; // 3m - testing,// 6h - production

export const GAME_POINTS_PER_GAME = 10000;
export const GAME_POINTS_REWARD_PER_SECOND = 100 / 216;

// Filters - initials
export const REPRESENTATION_DEFAULT_PRICE_MCAP = 'price';
export const REPRESENTATION_DEFAULT_TIMEFRAME = 'h1';
export const FILTERS_DEFAULT_LIQUIDITY_MIN = 10_000;
export const FILTERS_DEFAULT_MCAP_MIN = 100_000;
export const FILTERS_DEFAULT_LIQUIDITY_MAX = null;
export const FILTERS_DEFAULT_DISPLAYTOKENS = 'all';

export const DEFAULT_AD_BUBBLE_COLOR = '#0098ea';

// Mics - types
export type RepresentationValue = 'price' | 'marketCap';
export type PriceMCapFilterValue = 'price' | 'marketCap';
export type RepresentationTimeframe = 'h1' | 'h24' | 'd7' | 'd30';
export type DisplayTokensFilterValue = 'all' | 'watchlist';

export const RepresentationTimeframeMapping: {
  [key in RepresentationTimeframe]: string;
} = {
  h1: 'Hour',
  h24: '1 Day',
  d7: 'Week',
  d30: 'Month'
};

export const PriceMCapFilterValueMapping: {
  [key in RepresentationValue]: string;
} = {
  price: 'Price',
  marketCap: 'MCap'
};

export const TableSortValueMapping: {
  key: string;
  name: string;
}[] = [
  {
    key: 'priceUsd',
    name: 'Price'
  },
  {
    key: 'marketCapUsd',
    name: 'M Cap'
  },
  {
    key: 'liquidity',
    name: 'Liquidity'
  },
  {
    key: 'volumeUsd.h24',
    name: 'Volume'
  },
  {
    key: 'priceChangePercentage.h1',
    name: 'Hour'
  },
  {
    key: 'priceChangePercentage.h6',
    name: '6 Hours'
  },
  {
    key: 'priceChangePercentage.h24',
    name: 'Day'
  },
  {
    key: 'priceChangePercentage.d7',
    name: 'Week'
  },
  {
    key: 'priceChangePercentage.d30',
    name: 'Month'
  }
];

export const MinMaxFilters: {
  type: string;
  name: string;
  filter: string;
  remark?: string;
}[] = [
  {
    type: '$',
    name: 'Price',
    filter: 'price'
  },
  {
    type: '%',
    name: 'Price change',
    filter: 'priceChange',
    remark: '24H'
  },
  {
    type: '$',
    name: 'M Cap',
    filter: 'mCap'
  },
  {
    type: '$',
    name: 'Liquidity',
    filter: 'liquidity'
  },
  {
    type: '$',
    name: 'Volume',
    filter: 'volume'
  },
  {
    type: '$',
    name: 'TXNS',
    filter: 'txns',
    remark: '24H'
  },
  {
    type: 'H',
    name: 'Pair age',
    filter: 'pairAge'
  }
];

export const USD_OPTIONS = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};

export const BLANK_APP_FILTERS: IAppFilters = {
  price: {
    min: null,
    max: null
  },
  priceChange: {
    min: null,
    max: null
  },
  mCap: {
    min: null,
    max: null
  },
  liquidity: {
    min: null,
    max: null
  },
  volume: {
    min: null,
    max: null
  },
  txns: {
    min: null,
    max: null
  },
  pairAge: {
    min: null,
    max: null
  }
}

export const INITIAL_APP_FILTERS: IAppFilters = {
  ...BLANK_APP_FILTERS,
  mCap: {
    min: FILTERS_DEFAULT_MCAP_MIN,
    max: null
  },
  liquidity: {
    min: FILTERS_DEFAULT_LIQUIDITY_MIN,
    max: null
  },
};
