import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './WindowComponent.scss';

interface IProps {
  title: string;
  open: boolean;
  onClose: () => void;
  windowContent: any;
}

const WindowComponent = ({ title, open, onClose, windowContent }: IProps) => {
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: '12px'
        }
      }}
      onClose={onClose}
      open={open}>
      <DialogTitle>
        <Box className="window-title">{title}</Box>
        <Box height={28} onClick={onClose}>
          <CloseIcon
            sx={{
              width: '18px',
              height: '18px'
            }}
          />
        </Box>
      </DialogTitle>
      <DialogContent>{windowContent}</DialogContent>
    </Dialog>
  );
};

export default WindowComponent;
