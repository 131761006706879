import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { observer } from 'mobx-react';
import { useCallback, useContext } from 'react';
import { ChartType, Period } from '../../interfaces/chart';
import { AppStoreContext } from '../../store';

const TokenChartControls = observer(() => {
  const store = useContext(AppStoreContext);

  const onChangeType = useCallback((value: ChartType) => {
    if (!value) { return };
    store.setChart({...store.chart, chartType: value})
  }, [store]);

  const onChangePeriod = useCallback((value: Period) => {
    if (!value) { return };
    store.setChart({...store.chart, period: value})
  }, [store]);

  return (
    <div className="token-chart-controls">
      <div className="token-chart-controls--representation">
        <ToggleButtonGroup
          className="control-button-group"
          value={store.chart.chartType}
          exclusive
          onChange={(e, v) => onChangeType(v)}
          fullWidth
          aria-label="representation">
          <ToggleButton value="price">Price</ToggleButton>
          <ToggleButton value="marketCaps">MCap</ToggleButton>
          <ToggleButton value="holders">Holders</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <div className="token-chart-controls--timeframe">
        <ToggleButtonGroup
          className="control-button-group"
          value={store.chart.period}
          exclusive
          onChange={(e, v) => onChangePeriod(v)}
          fullWidth
          aria-label="timeframe">
          <ToggleButton value="w1">W</ToggleButton>
          <ToggleButton value="m1" disabled={store.chart.chartType === 'marketCaps'}>M</ToggleButton>
          <ToggleButton value="y1" disabled={store.chart.chartType === 'marketCaps'}>Y</ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
});

export default TokenChartControls;
