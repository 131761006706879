import { keepPreviousData, useQueries } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { fetchPromotedTokens } from '../../endpoints/promotedToken';
import { fetchTokens } from '../../endpoints/token';
import { AppStoreContext } from '../../store';
import Main from '../Main';
import TokenPage from '../TokenPage';

const Layout = observer(() => {
  const store = useContext(AppStoreContext);
  const [tokensRequest, promotedTokensRequest] = useQueries({
    queries: [
      {
        queryKey: ['allTokens'],
        queryFn: () => fetchTokens(),
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false
      },
      {
        queryKey: ['promotedTokens'],
        queryFn: () => fetchPromotedTokens(),
        placeholderData: keepPreviousData,
        refetchOnWindowFocus: false
      }
    ]
  });

  useEffect(() => {
    if (tokensRequest.isSuccess) {
      store.setAllTokens(tokensRequest.data || []);
    }
  }, [tokensRequest.isSuccess]);

  useEffect(() => {
    if (promotedTokensRequest.isSuccess) {
      store.setPromotedTokens(promotedTokensRequest.data);
    }
  }, [promotedTokensRequest.isSuccess]);

  if (!tokensRequest.isSuccess || !promotedTokensRequest.isSuccess) {
    return <div className="body-placeholder" style={{height: '90vh'}}></div>;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/tokens/:token" element={<TokenPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
});

export default Layout;
