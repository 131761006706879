export const onScroll = () => {
  const stickyElement = document.getElementById('filters-block');
  const bottomElement = document.getElementById('pagination');
  const topElement = document.getElementById('content-wrapper');
  let changePositionY = 0;

  if (topElement) {
    changePositionY = topElement?.offsetTop + 5;
  }

  if (stickyElement && topElement) {
    if (window.scrollY >= changePositionY) {
      stickyElement.style.position = 'fixed';
      stickyElement.style.top = '0px';
      stickyElement.style.bottom = 'auto';
      stickyElement.style.borderRadius = '0px';
      stickyElement.style.width =
        (topElement.offsetWidth - 33).toString() + 'px';
    } else {
      stickyElement.style.position = 'absolute';
      stickyElement.style.top = changePositionY + 'px';
      stickyElement.style.borderRadius = '18px';
      stickyElement.style.width =
        (topElement.offsetWidth - 33).toString() + 'px';
    }
  }
};
