import Box from '@mui/material/Box';
import { postEvent } from '@telegram-apps/sdk';
import { observer } from 'mobx-react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { BANNER_TOKENS_MIN_LIQUIDITY } from '../../constants';
import { IToken } from '../../interfaces/token';
import { AppStoreContext } from '../../store';
import { matchesAgoFilter, matchesFilter } from '../../utils/filterTokens';
import Logger from '../../utils/logger';
import { AppFilters } from '../AppFilters';
import BannerComponent from '../BannerComponent';
import BubblesComponent from '../BubblesComponent';
import TableComponent from '../TableComponent';
import './Main.scss';

const Main = observer(() => {
  const store = useContext(AppStoreContext);
  (window as any).postEvent = postEvent; // FOR DEV PURPOSES, temporary
  const [filteredTokens, setFilteredTokens] = useState<IToken[] | null>(null);

  useEffect(() => {
    try {
      postEvent('web_app_expand');
      postEvent('web_app_setup_swipe_behavior', { allow_vertical_swipe: false });
      postEvent('web_app_set_header_color', { color: '#f3f5f5' });
    } catch (e) {
      Logger.info('userAgent is not mini app, skipping miniapp behaviour...')
    }
  })

  useDeepCompareEffect(() => {
    const { appFilters: filters } = store;
    let tokens: IToken[] = [...store.allTokens];
    if (!tokens || !tokens.length) {
      return;
    }

    if (store.currentUser && store.filters.displayTokens === 'watchlist') {
      tokens = tokens.filter((token: IToken) =>
        store.currentUser?.tokenWatchlist?.includes(token.quoteTokenAddress)
      );
    }

    tokens = tokens.filter((token: IToken) => {
      return (
        matchesFilter(
          token.liquidity,
          filters.liquidity.min,
          filters.liquidity.max
        ) &&
        matchesFilter(token.priceUsd, filters.price.min, filters.price.max) &&
        matchesFilter(token.marketCapUsd, filters.mCap.min, filters.mCap.max) &&
        matchesFilter(
          token.volumeUsd?.h24,
          filters.volume.min,
          filters.volume.max
        ) &&
        matchesFilter(
          token.priceChangePercentage?.h24,
          filters.priceChange.min,
          filters.priceChange.max
        ) &&
        matchesFilter(
          token.transactionsCount,
          filters.txns.min,
          filters.txns.max
        ) &&
        matchesAgoFilter(
          token.poolCreatedAt,
          filters.pairAge.min,
          filters.pairAge.max
        )
      );
    });

    setFilteredTokens(tokens);
  }, [
    store.appFilters,
    store.allTokens,
    store.filters.displayTokens,
    store.currentUser
  ]);

  const trendingTokens = useMemo(() => {
    let tokens: IToken[] = [...store.allTokens];
    if (!tokens || !tokens.length) {
      return [];
    }

    return tokens
      .filter((token) => token.liquidity > BANNER_TOKENS_MIN_LIQUIDITY)
      .sort((a: IToken, b: IToken) => {
        const start = a?.volumeUsd?.h24 || 0;
        const end = b?.volumeUsd?.h24 || 0;
        return end - start;
      });
  }, [store.allTokens]);

  const largestGainers = useMemo(() => {
    let tokens: IToken[] = [...store.allTokens];
    if (!tokens || !tokens.length) {
      return [];
    }

    return tokens
      .filter((token) => token.liquidity > BANNER_TOKENS_MIN_LIQUIDITY)
      .sort((a: IToken, b: IToken) => {
        const start = a?.priceChangePercentage?.h24;
        const end = b?.priceChangePercentage?.h24;
        return end - start;
      });
  }, [store.allTokens]);

  if (!filteredTokens || !store.promotedTokens?.marketingPlaces)
    return <Box className="main-page"></Box>;

  return (
    <Box className="main-page">
      <Box className="banners-list">
        <BannerComponent
          type="trending"
          label="Trending"
          icon="🚀"
          mr={2}
          rows={trendingTokens.slice(0, 5)}
          token={null}
        />
        <BannerComponent
          type="promoted"
          label=""
          icon=""
          mr={2}
          rows={[]}
          token={store.promotedTokens?.marketingPlaces.trendingBlock || null}
        />
        <BannerComponent
          type="gainers"
          label="Largest gainers"
          icon="📈"
          mr={0}
          rows={largestGainers.slice(0, 5)}
          token={null}
        />
      </Box>
      <Box id="content-wrapper" className="content-wrapper">
        <AppFilters
          promotedToken={store.promotedTokens?.marketingPlaces.trendingBlock || null}
        />
        <BubblesComponent
          allTokens={filteredTokens}
          promotedTokens={store.promotedTokens}
        />
        <TableComponent
          allTokens={filteredTokens}
          promotedTokens={store.promotedTokens}
        />
      </Box>
    </Box>
  );
});

export default Main;
