import { Address, beginCell, Cell } from "@ton/core";
import { useTonAddress } from "@tonconnect/ui-react";
import { useCallback } from "react";
import useTonClient from "./useTonClient";

const useJettonBalance = () => {
  const { tonClient } = useTonClient();
  const walletAddress = useTonAddress();
  const walletParsedAddress: Address = Address.parse(walletAddress);

  const getJettonWalletParsedAddress = useCallback( async (jettonMasterAddress: string) => {
    const jettonMasterParsedAddress: Address = Address.parse(jettonMasterAddress);

    const jettonWalletResponse = await tonClient.runMethod(jettonMasterParsedAddress, 'get_wallet_address', [
      { type: 'slice', cell: beginCell().storeAddress(walletParsedAddress).endCell() }
    ]);

    const jettonWalletAddressCell: Cell = jettonWalletResponse.stack.readCell();
    const address = jettonWalletAddressCell.beginParse().loadAddress();

    return address;
  }, [tonClient, walletAddress]);

  const getWalletData = useCallback(async (address: Address) => {
    const response = await tonClient.runMethod(address, 'get_wallet_data', []);
    return response.stack.readNumber();
  }, [tonClient]);

  const getJettonBalance = useCallback( async (jettonMasterAddress: string): Promise<number | null> => {
    try {
      const address = await getJettonWalletParsedAddress(jettonMasterAddress);
      return await getWalletData(address);
    } catch (e: any) {
      if (e.message.includes('exit_code: -13')) {
        return 0;
      }

      console.warn('[useJettonBalance] error occured:', e)
      return null
    }

  }, [getJettonWalletParsedAddress, getWalletData])

  const getTonBalance = useCallback( async(): Promise<number | null> => {
    const balance = await tonClient.getBalance(walletParsedAddress);
    return Number(balance);
  }, [tonClient, walletParsedAddress])

  return { getJettonBalance, getTonBalance }
}

export default useJettonBalance;