import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import useInterval from '../../hooks/useInterval';
import { AppStoreContext } from '../../store';
import { recalculatePoints } from '../../utils/recalculatePoints';

interface IProps {
  authorizeCallback: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
}

export const AuthButtonContent = observer((props: IProps) => {
  const store = useContext(AppStoreContext);
  const [pointsAmount, setPointsAmount] = useState<string>('');

  const calculatePointsAmount = useCallback(() => {
    if (!store.currentUser) {
      return;
    }

    const {
      points,
      lastPointsRecalculatedAt: calculatedAt,
      lastBubbleGameStartedAt: startedAt
    } = store.currentUser.game;

    const calculatedPointsAmount = recalculatePoints(
      points,
      startedAt,
      calculatedAt
    );

    setPointsAmount(calculatedPointsAmount.toFixed(1));
  }, [store.currentUser]);

  useEffect(calculatePointsAmount);
  useInterval(calculatePointsAmount, 100);

  return (
    <button
      className={`sign-in text-base relative green ${props.isActive ? 'active' : ''}`}
      type="button"
      onClick={props.authorizeCallback}>
      {store.currentUser ? `Points ${pointsAmount}` : `Connect to earn`}
    </button>
  );
});
