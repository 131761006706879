import { observer } from "mobx-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { SWAP_TOKEN_TYPE } from "../../../constants";
import useGetActualPrice from '../../../hooks/useGetActualPrice';
import { ISwapToken } from "../../../interfaces/token";
import { AppStoreContext } from "../../../store";

interface IProps {
  token: ISwapToken
  tokenType: SWAP_TOKEN_TYPE
  value?: number
}

export const PriceUsd = observer((props: IProps) => {
  const store = useContext(AppStoreContext);

  const getActualPrice = useGetActualPrice();
  const [tokenPrice, setTokenPrice] = useState<number>(0);
  const [usdValue, setUsdValue] = useState<number>(0);

  const { token, value, tokenType } = props;

  const getTokenPrice = useCallback( async() => {
    const price = await getActualPrice(token.address);
    setTokenPrice(price);
  }, [token.address, getActualPrice])
  

  useEffect(() => {
    getTokenPrice();
  }, [getTokenPrice]);

  useEffect(() => {
    const usdValue = tokenPrice * (value || 0)
    setUsdValue(usdValue)
    store.setActualPrice(tokenPrice, tokenType)
  }, [tokenPrice, value, tokenType])


  return (
    <div className="usd-equivalent">
      ${usdValue.toFixed(2)}
    </div>
  )
})
