export const onHeaderScroll = () => {
  const stickyElement = document.getElementById('table-header');
  const topElement = document.getElementById('table-wrapper');
  let changePositionY = 0;

  if (topElement) {
    changePositionY =
      topElement?.offsetTop - (window.innerWidth < 600 ? 113 : 60);
  }

  if (stickyElement && topElement) {
    if (window.scrollY >= changePositionY) {
      stickyElement.style.width =
        (topElement.offsetWidth - 1).toString() + 'px';
      stickyElement.style.position = 'fixed';
      stickyElement.style.top = window.innerWidth < 600 ? '113px' : '60px';
      stickyElement.style.bottom = 'auto';
      stickyElement.style.borderRadius = '0px';
      stickyElement.style.overflow = 'hidden';
      stickyElement.style.display = 'block';
      stickyElement.style.zIndex = '1';
    } else {
      stickyElement.style.display = 'none';
      stickyElement.style.width =
        (topElement.offsetWidth - 1).toString() + 'px';
    }
  }
};
