import copy from 'copy-to-clipboard';
import { ReactComponent as CopyIcon } from '../../assets/images/copy.svg';

import { Popover } from '@mui/material';
import { useCallback, useState } from 'react';
import './styles.scss';

interface IProps {
  value: string;
}


const CopyButton = ({ value }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const copyText = useCallback((event: any) => {
    setAnchorEl(event.currentTarget);
    copy(value);
    const timeout = setTimeout(() => { setAnchorEl(null) }, 1000)
    return () => {
      clearTimeout(timeout);
    }
  }, [value])

  const open = Boolean(anchorEl);

  return (
    <>
      <button className="copy-text-button" onClick={copyText}>
        <CopyIcon className='copy-icon'/>
      </button>
      <Popover
        className="default-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        Copied to clipboard.
      </Popover>
    </>
  )
};

export default CopyButton;
