import DoneIcon from '@mui/icons-material/Done';
import { CircularProgress } from '@mui/material';
import { useTonConnectUI, WalletsModalState } from '@tonconnect/ui-react';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useSwapTransaction from '../../hooks/useSwapTransaction';
import { AppStoreContext } from '../../store';
import amplitudeInstance from '../../utils/amplitude';
import Logger from '../../utils/logger';

enum BUTTON_TEXT {
  SWAP = 'Swap',
  INSUFFICIENT_BALANCE = 'Insufficient balance',
  CONNECT_WALLET = 'Connect wallet'
}

export const ExchangeButton = observer(() => {
  const store = useContext(AppStoreContext);
  const [buttonText, setButtonText] = useState<BUTTON_TEXT>(
    BUTTON_TEXT.CONNECT_WALLET
  );
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [successTransaction, setSuccessTransaction] = useState<boolean>(false);
  const [tonConnectUI] = useTonConnectUI();
  const swapTransaction = useSwapTransaction();

  useEffect(() => {
    tonConnectUI.onModalStateChange((state: WalletsModalState) => {
      if (state.status === 'closed') {
        store.setSwapButtonLoading(false);
      }
    });
  });

  useEffect(() => {
    if (!successTransaction) {
      return;
    }

    const timeout = setTimeout(() => setSuccessTransaction(false), 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [successTransaction]);

  useEffect(() => {
    if (!store.swap.transactionError) {
      return;
    }

    const timeout = setTimeout(() => store.setSwapTransactionError(null), 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, [store.swap.transactionError]);

  const isInsufficientBalance = useMemo(() => {
    if (store.swap.straightDirection) {
      const requestedValue =
        (parseInt(store.swap.simulatedSwap?.offerUnits as string) || 0) /
        10 ** store.swap.baseToken.decimals;
      if ((store.swap.baseTokenBalance || 0) < requestedValue) {
        return true;
      }
    }

    if (!store.swap.straightDirection) {
      if (!store.swap.quoteToken) {
        return false;
      }
      const requestedValue =
        (parseInt(store.swap.simulatedSwap?.offerUnits as string) || 0) /
        10 ** store.swap.quoteToken.decimals;
      if ((store.swap.quoteTokenBalance || 0) < requestedValue) {
        return true;
      }
    }
  }, [
    store.swap.straightDirection,
    store.swap.quoteToken,
    store.swap.baseToken.decimals,
    store.swap.baseTokenBalance,
    store.swap.quoteTokenBalance,
    store.swap.simulatedSwap?.offerUnits
  ]);

  useEffect(() => {
    if (store.isWalletConnected) {
      if (
        !store.swap.simulatedSwap?.offerUnits ||
        store.swap.simulatedSwap?.offerUnits === '0'
      ) {
        setButtonText(BUTTON_TEXT.SWAP);
        setButtonDisabled(true);
        return;
      }

      if (isInsufficientBalance) {
        setButtonText(BUTTON_TEXT.INSUFFICIENT_BALANCE);
        setButtonDisabled(true);
        return;
      }

      setButtonText(BUTTON_TEXT.SWAP);
      setButtonDisabled(false);
    } else {
      setButtonText(BUTTON_TEXT.CONNECT_WALLET);
      setButtonDisabled(false);
    }
  }, [
    store.isWalletConnected,
    store.swap.simulatedSwap,
    isInsufficientBalance
  ]);

  const swapButtonCallback = useCallback(async () => {
    if (store.swap.buttonLoading) {
      return;
    }

    if (store.isWalletConnected) {
      if (!store.swap.simulatedSwap) {
        Logger.warn('Trying to swap when simulatedSwap is not ready');
        return;
      }
      store.setSwapButtonLoading(true);
      amplitudeInstance.event('swapToken', store.swap.simulatedSwap);
      const result = await swapTransaction(store.swap.simulatedSwap);
      if (result.error) {
        amplitudeInstance.event('swapTokenError', { error: result.error });
        store.setSwapTransactionError(result.error);
      } else {
        amplitudeInstance.event('swapTokenSuccess');
        setSuccessTransaction(true);
      }

      store.setSwapButtonLoading(false);
    } else {
      store.setSwapButtonLoading(true);
      tonConnectUI.openModal();
    }
  }, [store.isWalletConnected, store.swap.buttonLoading, tonConnectUI]);

  const renderError = () => {
    if (!store.swap.transactionError) {
      return null;
    }

    return (
      <div className="transaction-error">
        <div className="transaction-error--header">Error</div>
        <div className="transaction-error--body">
          {store.swap.transactionError}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderError()}
      <button
        className="exchange-button"
        disabled={buttonDisabled}
        onClick={swapButtonCallback}>
        {store.swap.buttonLoading ? (
          <CircularProgress sx={{ width: '20px', height: '20px' }} />
        ) : successTransaction ? (
          <DoneIcon />
        ) : (
          buttonText
        )}
      </button>
    </div>
  );
});
