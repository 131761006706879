import missing from '../assets/images/missing.png';
import { API_BASE_URL } from "../constants";
import { IPromotedToken } from "../interfaces/promotedToken";

export const promotedTokenImageURL = (promotedToken: IPromotedToken): string => {
  const imagePath = promotedToken.imageUrl || promotedToken?.token?.imagePath

  if (imagePath) {
    if (imagePath.startsWith('http')) {
      return imagePath;
    } else {
      return `${API_BASE_URL}${imagePath}`;
    }
  } else {
    return missing;
  }
}