import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Box from '@mui/material/Box';
import { on, postEvent } from '@telegram-apps/sdk';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import missing from '../../assets/images/missing.png';
import { API_BASE_URL } from '../../constants';
import { ISwapToken } from '../../interfaces/token';
import { AppStoreContext } from '../../store';
import { Exchanger } from '../Exchanger';
import TrendingTokensPanel from '../TrendingTokensPanel';
import TokenAboutInfo from './TokenAboutInfo';
import TokenChartContainer from './TokenChartContainer';
import TokenDescription from './TokenDescription';
import TokenMainInfo from './TokenMainInfo';
import './TokenPage.scss';
import TokenTabs from './TokenTabs';

const TokenPage = observer(() => {
  const store = useContext(AppStoreContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const token = (store.allTokens || []).find(
    (item) => item.quoteTokenAddress === pathname.split('/tokens/')[1]
  );

  useEffect(() => {
    try {
      postEvent('web_app_setup_back_button', { is_visible: true });
      const removeListener = on('back_button_pressed', () => {
        navigate('/');
      });

      return () => {
        postEvent('web_app_setup_back_button', { is_visible: false });
        removeListener();
      };
    } catch (e) {}
  });

  const escFunction = useCallback(
    (event: any) => {
      if (event.key === 'Escape') {
        navigate('/');
      }
    },
    [navigate]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction]);

  if (!token) return null;

  const quoteTokenSwap: ISwapToken = {
    address: token.quoteTokenAddress,
    decimals: token.decimals,
    symbol: token.symbol,
    imagePath: !!token.imagePath ? API_BASE_URL + token.imagePath : missing
  };

  return (
    <Box className="token-page-wrapper">
      <button className="back-button" onClick={() => navigate('/')}>
        <ArrowBackIosNewIcon />
        esc
      </button>
      <Box className="main-info-block paper">
        <TokenMainInfo token={token} />
        <Exchanger token={quoteTokenSwap} />
        <Box className="" sx={{ display: { xs: 'block', md: 'none' } }}>
          <TokenTabs token={token} />
          <TokenChartContainer token={token} />
        </Box>
        <TokenDescription token={token} />
        <Box className="" sx={{ display: { xs: 'block', md: 'none' } }}>
          {token.tokenSocials?.description && <TokenAboutInfo token={token} />}
          <TrendingTokensPanel />
        </Box>
      </Box>
      <Box
        className="chart-block paper"
        sx={{ display: { xs: 'none', md: 'block' } }}>
        <TokenChartContainer token={token} />
        {token.tokenSocials?.description && <TokenAboutInfo token={token} />}
        <TrendingTokensPanel />
      </Box>
    </Box>
  );
});

export default TokenPage;
