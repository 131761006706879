
import { API_BASE_URL, LOCAL_STORAGE_TON_TOKEN_KEY } from "../constants";
import { HTTP_METHOD, IFetchOptions } from "../interfaces/common";

export const unauthorizedRequest = async (path: string, method: HTTP_METHOD = HTTP_METHOD.GET, body?:  XMLHttpRequestBodyInit) => {
  const fetchOptions: IFetchOptions = { method };

  const response = await fetch(
    new URL(path, API_BASE_URL),
    fetchOptions
  );

  if (!response.ok) {
    console.error(`[${path}]: Failed with ${response.status}, ${response.statusText}`);
  }

  return {
    data: await response.json(),
    status: response.status,
    success: response.ok
  }
}

export const authorizedRequest = async (path: string, method: HTTP_METHOD = HTTP_METHOD.GET, body?:  XMLHttpRequestBodyInit, headers?: HeadersInit) => {
  const accessToken: string | null = localStorage.getItem(LOCAL_STORAGE_TON_TOKEN_KEY);
  if (!accessToken) {
    return false;
  }

  const authHeaders: HeadersInit = {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  }

  const fetchOptions: IFetchOptions = { method, body, headers: { ...headers, ...authHeaders } };

  const response = await fetch(
    new URL(path, API_BASE_URL),
    fetchOptions
  );

  if ([401, 403].includes(response.status)) {
    localStorage.setItem(LOCAL_STORAGE_TON_TOKEN_KEY, '');
    // disconnect TON Connect UI?
  }

  if (!response.ok) {
    console.error(`[${path}]: Failed with ${response.status}, ${response.statusText}`);
    throw new Error(response.statusText)
  }

  return {
    data: await response.json(),
    status: response.status
  }
}