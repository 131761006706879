import { sum } from 'lodash';
const SQUARE_FULFILLMENT_PERCENTAGE = 0.81;
const MAX_SQUARE_COEF = 0.05;
const MIN_SQUARE_COEF = 0.007;

export function newCalculation(arrayValues: number[], square: number): number[] {
  // total square of all the items should not be more than X * square of canvas
  const totalSquare = square * SQUARE_FULFILLMENT_PERCENTAGE;
  // define max and min square, in case if there's too many / too little
  const maxSquare = totalSquare * MAX_SQUARE_COEF;
  const minSquare = totalSquare * MIN_SQUARE_COEF;

  const arrayOfRoots = arrayValues.map((x) => Math.sqrt(x))
  const sumValues = sum(arrayOfRoots) + 0.001; // sum can be 0

  const squareRatio = totalSquare / sumValues;

  // now, we assign bubble squares proportionally
  const calculatedSquares = arrayOfRoots.map((x) => {
    let square = squareRatio * x;
    if (square < minSquare) {
      square = minSquare;
    } else if (square > maxSquare) {
      square = maxSquare;
    }

    return square;
  })

  // now we cut off the values to avoid overflow if way too many minRadiuses applied
  // ASSUMING ARRAY IS SORTED!
  let squareSum = 0;
  const updatedSquares: number[] = []
  calculatedSquares.forEach((square) => {
    if (squareSum > totalSquare) {
      return;
    }
    squareSum += square;
    updatedSquares.push(square);
  })

  const radiuses = updatedSquares.map(square => Math.sqrt(square / Math.PI))
  return radiuses;
}
