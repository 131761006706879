import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';
import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useScreenType from 'react-screentype-hook';
import { useDeepCompareMemo } from 'use-deep-compare';
import search from '../../assets/images/Vector.svg';
import missing from '../../assets/images/missing.png';
import oops from '../../assets/images/oops.png';
import { API_BASE_URL } from '../../constants';
import { IPromotedToken } from '../../interfaces/promotedToken';
import { IToken } from '../../interfaces/token';
import { AppStoreContext } from '../../store';
import amplitudeInstance from '../../utils/amplitude';
import googleAnalytics from '../../utils/bubblesGa';
import { promotedTokenImageURL } from '../../utils/promotedTokenUtils';
import { sortTokens } from '../../utils/tokenUtils';
import { transformNumber } from '../../utils/transformNumber';
import PriceChange from '../PriceChange/PriceChange';

interface IProps {
  open: boolean;
  onOpenClick: (value: boolean) => void;
  promotedToken: IPromotedToken | null;
}

const SearchComponent = observer(
  ({ open, onOpenClick, promotedToken }: IProps) => {
    const store = useContext(AppStoreContext);
    const screenType = useScreenType();
    const [anchorPop, setAnchorPop] = useState<HTMLDivElement | null>(null);
    const tokens = [...store.allTokens];
    const navigate = useNavigate();
    const [localOpen, setLocalOpen] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');

    const sortedTokens: IToken[] = useDeepCompareMemo(() => {
      return sortTokens([...tokens], 'liquidity', 'desc');
    }, [tokens]);

    const filteredOptions = sortedTokens.filter((token: IToken) => {
      return (
        token.symbol.toLowerCase().includes(searchValue.toLowerCase()) ||
        (token.tokenSocials?.tokenName || '')
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    });

    const onBlur = useCallback(
      (e: React.SyntheticEvent) => {
        const timeout = setTimeout(() => {
          onOpenClick(false);
          setLocalOpen(false);
          setSearchValue('');
        }, 150);

        return () => {
          clearTimeout(timeout);
        };
      },
      [onOpenClick]
    );

    const handleClose = () => {
      setAnchorPop(null);
      setSearchValue('');
    };

    const ListboxComponent = () => {
      const imageURL = promotedTokenImageURL(promotedToken as IPromotedToken);

      const goToLink = () => {
        googleAnalytics.event('ad_click', {
          url: promotedToken?.url,
          event_source: 'search_component'
        });
        amplitudeInstance.event('adClick', {
          place: 'searchBar',
          url: promotedToken?.url
        });
        window.open(promotedToken?.url, '_blank');
      };
      return (
        <Box className="search-listbox">
          {!!promotedToken && (
            <Box
              className="ad-token"
              onClick={() => {
                goToLink();
              }}>
              <Box className="ad-token-icon">
                <img width={56} height={56} src={imageURL} alt="logo" />
              </Box>
              <Box className="ad-token-info">
                <Box className="ad-token-name">{promotedToken.symbol}</Box>
                <Box className="ad-token-desc">
                  <span>{promotedToken.description}</span>
                </Box>
              </Box>
            </Box>
          )}
          <Box className="search-label">Trending tokens</Box>
          <Box className="search-list">
            {(filteredOptions || []).map((item, index) => {
              return <OptionComponent key={index} option={item} />;
            })}
            {!filteredOptions.length && <NoOptions />}
          </Box>
        </Box>
      );
    };

    const NoOptions = () => {
      return (
        <Box className="no-options">
          <Box className="no-options-icon">
            <img
              width={128}
              height={96}
              src={oops}
              alt="logo"
              className="no-options-img"
            />
          </Box>
          <Box className="no-options-text">
            No results for "{searchValue}". Please try another token
          </Box>
        </Box>
      );
    };

    const OptionComponent = ({ option }: any) => {
      return (
        <Box
          key={option.id}
          className="search-option"
          onClick={() => {
            navigate(`/tokens/${option.quoteTokenAddress}`);
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              width={30}
              height={30}
              src={
                !!option.imagePath ? API_BASE_URL + option.imagePath : missing
              }
              alt="logo"
              className="logo-image"
            />
            <Box className="truncate-name">
              {option.tokenSocials?.tokenName}
            </Box>
            <Box ml={1} className="token-symbol">
              ${option.symbol}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
            <Box>${transformNumber(option?.priceUsd || 0)}</Box>
            <Box sx={{ minWidth: '60px', marginLeft: '8px' }}>
              <PriceChange value={option.priceChangePercentage?.h24} />
            </Box>
          </Box>
        </Box>
      );
    };

    const SearchElements = () => {
      return (
        <>
          <TextField
            onChange={(e) => setSearchValue(e.target.value)}
            onBlur={(e) => {
              if (!screenType.isMobile) {
                onBlur(e);
              }
            }}
            className={`search-input-component ${open ? 'opened' : ''}`}
            placeholder="Search"
            value={searchValue}
            fullWidth={localOpen}
            autoFocus={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img width={14} height={14} src={search} alt="logo" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <CloseRoundedIcon
                    className="close"
                    width={11}
                    height={11}
                    onClick={() => {
                      onOpenClick(false);
                      setLocalOpen(false);
                      setSearchValue('');
                      setAnchorPop(null);
                    }}
                  />
                </InputAdornment>
              )
            }}
          />
          <ListboxComponent />
        </>
      );
    };

    const PaperComponent = () => {
      return (
        <Paper elevation={0} className="search-component-paper">
          <SearchElements />
        </Paper>
      );
    };

    return (
      <>
        <>
          {!localOpen && (
            <Box
              id="search-element"
              tabIndex={0}
              className={`search-component ${open ? 'opened' : ''}`}
              onClick={(event) => {
                onOpenClick(true);
                setTimeout(() => {
                  setLocalOpen(true);
                }, 0);
                if (screenType.isMobile) {
                  setAnchorPop(event.currentTarget);
                }
              }}>
              <Box className="icon-with-label">
                <img width={14} height={14} src={search} alt="logo" />
                Search
              </Box>
              <Box className="slash-icon">/</Box>
            </Box>
          )}
          {localOpen && (
            <>
              <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                <PaperComponent />
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Popover
                  open={Boolean(anchorPop)}
                  anchorEl={anchorPop}
                  className="search-popover"
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  slotProps={{
                    paper: {
                      className: 'search-popover-paper',
                      elevation: 0
                    }
                  }}>
                  <SearchElements />
                </Popover>
              </Box>
            </>
          )}
        </>
      </>
    );
  }
);

export default SearchComponent;
