import { DateTime, Interval } from "luxon";
import { FilterInputValue } from "../interfaces/filters";
export const maxParam = (value: FilterInputValue): number => {
  if (value === 0) {
    return 0
  }

  return value || Number.POSITIVE_INFINITY;
}

export const minParam = (value: FilterInputValue): number => {
  if (value === 0) {
    return 0;
  }

  return value ? Number(value) : Number.NEGATIVE_INFINITY
}

export const matchesFilter = (tokenValue: number | undefined, min: FilterInputValue, max: FilterInputValue): boolean => (
  Number(tokenValue) > minParam(min) && Number(tokenValue) < maxParam(max)
)

export const matchesAgoFilter = (date: string, min: FilterInputValue, max: FilterInputValue): boolean => {
  if (!date && (min || max)) {
    return false
  }

  if (!min && !max) {
    return true
  }

  const dateHoursAgo = hoursAgo(date);
  return Number(dateHoursAgo) > minParam(min) && Number(dateHoursAgo) < maxParam(max)
}
// date = 2024-05-14T19:13:11.000Z
export const hoursAgo = (date: string): number | null => {
  try {
    if (!date) { return null }

    const end = DateTime.now();
    const start = DateTime.fromISO(date);
    const diff = Interval.fromDateTimes(start, end).length('hours')
    if (!diff) {
      console.warn('incorrect time calculation', diff)
    }
    return diff;
  } catch(e) {
    return null
  }
}
