import Box from '@mui/material/Box';
import { AnyRecord } from 'dns';
import { DateTime } from 'luxon';
import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { ChartType } from '../../interfaces/chart';
import { formatCompactUsd } from '../../utils';
import { transformNumber } from '../../utils/transformNumber';

interface IProps {
  records: AnyRecord[];
  loading: boolean;
  chartType: ChartType;
  lastPriceUsd: number;
}

const TokenChart = ({ records, loading, chartType, lastPriceUsd }: IProps) => {
  let maxValue = 0,
    minValue = 0,
    middleValue = 0,
    subMax = 0,
    subMin = 0;

  const transformValue = (value: number) => {
    if (chartType === 'price') {
      return `$${transformNumber(value)}`;
    } else if (chartType === 'marketCaps') {
      return formatCompactUsd(value) || undefined;
    } else {
      return value.toFixed(0);
    }
  }
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <Box className="custom-tooltip">
          <Box className="label">{transformValue(payload[0].value)}</Box>
          <Box className="desc">
            {DateTime.fromISO(payload[0]?.payload?.timestamp).toFormat(
              'dd MMMM yy, HH:mm'
            )}
          </Box>
        </Box>
      );
    }

    return null;
  };

  let chartDataKey = 'price';
  switch(chartType) {
    case 'price':
      chartDataKey = 'price';
      break;
    case 'holders':
      chartDataKey = 'holders';
      break;
    case 'marketCaps':
      chartDataKey = 'marketCap';
      break;
  }

  if (!loading) {
    maxValue = Math.max(...records.map((record: any) => record[chartDataKey]));
    minValue = Math.min(...records.map((record: any) => record[chartDataKey]));
    middleValue = (minValue + maxValue) / 2;
    subMax = (maxValue + middleValue) / 2;
    subMin = (minValue + middleValue) / 2;
  }

  const chartData = () => {
    if (chartType === 'price') {
      return [
        ...records,
        { price: lastPriceUsd, timestamp: new Date().toISOString() }
      ]
    } else {
      return [...records]
    }
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={chartData().sort((a: any, b: any) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0
        }}>
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#6fa438" />
            <stop offset='50%' stopColor="#fff"/>
            { chartDataKey !== 'holders' && (
              <>
                <stop offset='50%' stopColor="#fff"/>
                <stop offset="100%" stopColor="#ED432C"/>
              </>
            ) }
           
          </linearGradient>
          <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#6fa438" />
            <stop offset='50%' stopColor="#6fa438"/>
            { chartDataKey !== 'holders' && (
              <>
                <stop offset='50%' stopColor="#ED432C"/>
                <stop offset="100%" stopColor="#ED432C"/>
              </>
            ) }
          </linearGradient>
        </defs>
        <ReferenceLine
          y={maxValue}
          stroke="#d8dbdb"
          strokeDasharray="6 6"
          label={{
            position: 'right',
            value: transformValue(maxValue),
            fontSize: 12,
            fill: '#798686'
          }}
        />
        <ReferenceLine
          y={minValue}
          stroke="#d8dbdb"
          strokeDasharray="6 6"
          label={{
            position: 'right',
            value: transformValue(minValue),
            fontSize: 12,
            fill: '#798686'
          }}
        />
        <ReferenceLine
          y={middleValue}
          stroke="#d8dbdb"
          strokeDasharray="2 2"
          label={{
            position: 'right',
            value: transformValue(middleValue),
            fontSize: 12,
            fill: '#798686'
          }}
        />
        <ReferenceLine
          y={subMax}
          stroke="#d8dbdb"
          strokeDasharray="5 5"
          label={{
            position: 'right',
            value: transformValue(subMax),
            fontSize: 12,
            fill: '#798686'
          }}
        />
        <ReferenceLine
          y={subMin}
          stroke="#d8dbdb"
          strokeDasharray="5 5"
          label={{
            position: 'right',
            value: transformValue(subMin),
            fontSize: 12,
            fill: '#798686'
          }}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        <XAxis hide dataKey="timestamp" />
        <YAxis hide domain={['auto', 'auto']} />
        <Area
          baseValue={middleValue}
          type="linear"
          dataKey={chartDataKey}
          strokeWidth={2}
          stroke="url(#colorUv2)"
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default TokenChart;
