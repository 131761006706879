import { orderBy } from 'lodash';
import {
  RepresentationTimeframe,
  RepresentationValue
} from '../../constants';
import { CanvasPool } from '../../core/models/token.model';
import { BubblesSort } from '../../interfaces/filters';
import { IToken, PriceChangePercentageValue } from '../../interfaces/token';

export const applyFilters = (
  tokens: IToken[],
  priceValue: RepresentationValue,
  timeframeValue: RepresentationTimeframe,
  bubblesSort: BubblesSort,
): CanvasPool[] => {

  const calculateBubbleValue = (token: IToken) => {
    const value =  priceValue === 'price'
    ? token.priceChangePercentage[
        priceChangePercentageField as PriceChangePercentageValue
      ]
    : token.marketCapUsd;
    return Number(value);
  }

  const absBubbleValue = (token: IToken) => {
    return Math.abs(calculateBubbleValue(token))
  }

  let priceChangePercentageField: string = 'marketCapUsd';
  let bubbleType: string = 'marketCap';
  let tokensToProceed: IToken[] = [...tokens];

  if (priceValue === 'price') {
    priceChangePercentageField = 'h24';
    bubbleType = 'price';
  }

  if (timeframeValue && priceValue === 'price') {
    priceChangePercentageField = timeframeValue; // h1, h6, d30
  }

  if (bubblesSort === 'gainers') {
    tokensToProceed = orderBy(tokensToProceed, [function(t) { return t.priceChangePercentage.h24 }], ['desc'])
  } else if (bubblesSort === 'recent') {
    tokensToProceed = orderBy(tokensToProceed, [function(t) { return new Date(t.poolCreatedAt) }], ['desc'])
  } else if (bubblesSort === 'default') {
    tokensToProceed = orderBy(tokensToProceed, [absBubbleValue], ['desc'])
  }

  return tokensToProceed.map((token): CanvasPool => {
    const priceChangePercentageValue = calculateBubbleValue(token)
    const positiveDynamic =
      priceValue === 'price'
        ? token.priceChangePercentage[
            priceChangePercentageField as PriceChangePercentageValue
          ] >= 0
        : token.priceChangePercentage.h1 >= 0;
    return {
      name: token.tokenSocials?.tokenName,
      price_change_percentage: priceChangePercentageValue,
      image_url: token.imagePath as string, // TODO: OR BLANK IMAGE
      symbol: token.symbol,
      positive_dynamic: positiveDynamic,
      quoteTokenAddress: token.quoteTokenAddress,
      type: bubbleType
    };
  });
};
