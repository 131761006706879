import './styles.scss';

interface IProps {
  content: React.ReactElement | string;
  emoji: string;
  isActive: boolean;
  onClick: () => void;
  width?: number;
}

const TransparentButton = ({
  content,
  onClick,
  emoji,
  isActive,
  width
}: IProps) => {
  return (
    <button
      className={`transparent-button transparent-button--${width} ${isActive ? 'active' : ''}`}
      onClick={onClick}>
      <span className="transparent-button--emoji">{emoji}</span>
      <span className="transparent-button--content">{content}</span>
    </button>
  );
};

export default TransparentButton;
