export class Logger {
  public static log(...args: any[]): void {
    if (this._isLoggingEnabled()) {
      this.console.log(...args)
    }
  }

  public static info(...args: any[]): void {
    if (this._isLoggingEnabled()) {
      this.console.info(...args)
    }
  }

  public static warn(...args: any[]): void {
    if (this._isLoggingEnabled()) {
      this.console.warn(...args)
    }
  }
  public static error(...args: any[]): void {
    if (this._isLoggingEnabled()) {
      this.console.error(...args)
    }
  }

  public static poke<T>(value: T, label: string = ''): T {
    if (this._isLoggingEnabled()) {
      this.console.log(`${label}: `, value)
    }
    return value
  }
  private static console = window.console
  private static _isLoggingEnabled(): boolean {
    return process.env.REACT_APP_LOGGING_ENABLED === 'true'
  }
}

export default Logger
