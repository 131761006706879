import { DEX } from "@ston-fi/sdk";
import { TonClient } from '@ton/ton';
import { TONCENTER_API_KEY } from "../constants";

const tonClient = new TonClient({
  endpoint: "https://toncenter.com/api/v2/jsonRPC",
  apiKey: TONCENTER_API_KEY || undefined
});

const dex = tonClient.open(new DEX.v1.Router());

const useTonClient = () => {
  return { tonClient, dex };
}

export default useTonClient;