import Box from '@mui/material/Box';
import { useMutation } from '@tanstack/react-query';
import { observer } from 'mobx-react';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect, useDeepCompareMemo } from 'use-deep-compare';
import SketchInstance from '../../core/canvas/bubblesCanvas';
import { CanvasPool } from '../../core/models/token.model';
import { startGame } from '../../endpoints/user';
import { IPromotedTokensResponse } from '../../interfaces/promotedToken';
import { IToken } from '../../interfaces/token';
import { AppStoreContext } from '../../store';
import googleAnalytics from '../../utils/bubblesGa';
import { gameSecondsLeft } from '../../utils/recalculatePoints';
import './BubblesComponent.css';
import { applyFilters } from './applyFilters';

interface IProps {
  allTokens: IToken[];
  promotedTokens: IPromotedTokensResponse | null;
}

const BubblesComponent = observer(({ allTokens, promotedTokens }: IProps) => {
  const sketchInstanceRef = useRef<any>(null);
  const store = useContext(AppStoreContext);
  const navigate = useNavigate();

  const startGameMutation = useMutation({
    mutationFn: startGame,
    onError: (e) => console.log(e),
    onSuccess: (data, error) => store.setCurrentUser(data)
  });

  useEffect(() => {
    const timeout = setTimeout(googleAnalytics.pageView, 2000);
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    return () => {
      store.canvasRendered(false);
      sketchInstanceRef.current.remove();
      sketchInstanceRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (store.startGameCalled) {
      startGameMutation.mutate();
      store.startGame(false);
    }
  }, [store.startGameCalled, store, startGameMutation]);

  useEffect(() => {
    if (!store.isCanvasRendered || !promotedTokens) {
      return;
    }

    if (!promotedTokens.marketingPlaces.bubbleOne) {
      return; // this marketing place is disabled
    }

    sketchInstanceRef.current?.renderPromotedBubble(promotedTokens.marketingPlaces.bubbleOne);
  }, [store.isCanvasRendered, promotedTokens]);

  useEffect(() => {
    if (!store.isCanvasRendered) {
      return;
    }

    if (!store.currentUser) {
      sketchInstanceRef.current?.removeGameBubble();
    } else {
      const secondsLeft = gameSecondsLeft(
        store.currentUser.game.lastBubbleGameStartedAt
      );
      sketchInstanceRef.current?.renderGameBubble({
        shown: true,
        timeLeftSeconds: secondsLeft
      });
    }
  }, [store.currentUser, store.isCanvasRendered]);

  const displayableBubbles = useDeepCompareMemo(() => {
    return applyFilters(
      allTokens as IToken[],
      store.filters.representation,
      store.filters.representationTimeframe,
      store.filters.bubblesSort
    );
  }, [
    allTokens,
    store.filters.representation,
    store.filters.representationTimeframe,
    store.filters.bubblesSort
  ]);

  const goToTokenPage = useCallback((tokenAddress: string) => {
    navigate(`/tokens/${tokenAddress}`);
  }, [navigate]);

  const initializeSketch = useCallback(
    (displayableBubbles: CanvasPool[]) => {
      sketchInstanceRef.current = SketchInstance(
        displayableBubbles,
        null,
        null,
        store.startGame.bind(store),
        store.canvasRendered.bind(store),
        goToTokenPage
      );
    },
    [store, goToTokenPage]
  );

  useDeepCompareEffect(() => {
    if (sketchInstanceRef.current) {
      if (!store.isCanvasRendered) {
        return;
      }
      sketchInstanceRef.current.redrawBubbles(displayableBubbles);
    } else {
      initializeSketch(displayableBubbles);
    }
  }, [store.isCanvasRendered, initializeSketch, displayableBubbles]);

  return (
    <Box>
      <Box
        id="bubbles-page"
        className="bubbles-page"
        sx={{ position: 'relative' }}>
        <div className="min-height-wrapper" id="sketch-id"></div>
      </Box>
    </Box>
  );
});

export default BubblesComponent;
