import { Box } from "@mui/material";
import { observer } from "mobx-react";
import { useContext } from "react";
import { AppStoreContext } from "../../../store";

export const SwapHeader = observer(() => {
  const store = useContext(AppStoreContext);

  return (
    <Box className="swap-headers">
        <div className="swap-headers--title">Swap tokens</div>
        <div className="swap-headers--tolerance">Slippage tolerance: {store.swap.slippageTolerance}</div>
    </Box>
  )
})
