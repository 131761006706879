import { observer } from 'mobx-react';
import { useCallback, useContext } from 'react';
import { ReactComponent as StartF } from '../../assets/images/StarFilled.svg';
import { ReactComponent as StarB } from '../../assets/images/StarGrey.svg';
import { toggleWatchlist } from '../../endpoints/user';
import { AppStoreContext } from '../../store';

import { useMutation } from '@tanstack/react-query';
import { useTonConnectUI } from '@tonconnect/ui-react';

interface IProps {
  token: string;
}

const StarIndicator = observer(({ token }: IProps) => {
  const store = useContext(AppStoreContext);
  const [tonConnectUI] = useTonConnectUI();
  const toggleWatchlistMutation = useMutation({
    mutationFn: toggleWatchlist,
    onError: (e) => console.log(e),
    onSuccess: (data, error) => store.setCurrentUser(data)
  });

  const watchlist = JSON.stringify(store?.currentUser?.tokenWatchlist);
  const tokenIsWatched: boolean = watchlist
    ? JSON.parse(watchlist).includes(token)
    : false;

  const buttonCallback = useCallback(
    (e: any) => {
      e.preventDefault();
      if (store.currentUser) {
        toggleWatchlistMutation.mutate(token);
      } else {
        tonConnectUI.openModal();
      }
    },
    [store.currentUser, token, tonConnectUI, toggleWatchlistMutation]
  );

  return tokenIsWatched ? (
    <StartF
      className="star-filled open-block"
      width={19}
      height={19}
      onClick={buttonCallback}
    />
  ) : (
    <StarB
      className="star-blank open-block"
      width={19}
      height={19}
      onClick={buttonCallback}
    />
  );
});

export default StarIndicator;
