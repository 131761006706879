import { pTON } from "@ston-fi/sdk";
import { SenderArguments } from '@ton/ton';
import { SendTransactionResponse, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useCallback } from 'react';
import { TON_CONTRACT_ADDRESS } from "../constants";
import { ISimulateSwapResponse } from "../interfaces/stonFi";
import { queryId } from "../utils";
import useTonClient from "./useTonClient";

interface ISwapTransactionResponse {
  boc?: string,
  error?: string
}

const useSwapTransaction = () => {
  const wallet = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();
  const { dex } = useTonClient()

  return useCallback(
    async (swapParameters: ISimulateSwapResponse): Promise<ISwapTransactionResponse> => {
      let txParams: SenderArguments | null = null;
      if (swapParameters.askAddress === TON_CONTRACT_ADDRESS) {
        txParams = await dex.getSwapJettonToTonTxParams({
          proxyTon: new pTON.v1(),
          userWalletAddress: wallet,
          // referralAddress: TON_REFERRAL_ADDRESS,
          offerAmount: swapParameters.offerUnits,
          offerJettonAddress: swapParameters.offerAddress,
          minAskAmount: swapParameters.minAskUnits,
          queryId: queryId()
        })
      }

      if (swapParameters.offerAddress === TON_CONTRACT_ADDRESS) {
        txParams = await dex.getSwapTonToJettonTxParams({
          proxyTon: new pTON.v1(),
          userWalletAddress: wallet,
          // referralAddress: TON_REFERRAL_ADDRESS,
          offerAmount: swapParameters.offerUnits,
          askJettonAddress: swapParameters.askAddress,
          minAskAmount: swapParameters.minAskUnits,
          queryId: queryId()
        });
      }

      if (!txParams) {
        console.warn('[useSwapTransaction] Cannot find TON contract among ask/offer addresses.');
        return {
          error: 'Cannot find TON contract among ask/offer addresses.'
        }
      }

      try {
        const transactionResult: SendTransactionResponse = await tonConnectUI.sendTransaction({
          validUntil: Date.now() + 1000000,
          messages: [
            {
              address: txParams.to.toString(),
              amount: txParams.value.toString(),
              payload: txParams.body?.toBoc().toString("base64"),
            },
          ],
        });

        return transactionResult
      } catch (err: any) {
        console.warn(err.toString());

        return {
          error: 'Transaction was rejected.'
        }
      }

    }, [dex, tonConnectUI, wallet]
  )
};

export default useSwapTransaction;