import Box from '@mui/material/Box';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { observer } from 'mobx-react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { TonProofDemoApi } from '../../TonProofDemoApi';
import { fetchCurrentUser } from '../../endpoints/user';
import { AppStoreContext } from '../../store';

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AuthButtonContent } from './AuthButtonContent';
import './style.scss';

interface IAuthButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isActive: boolean;
}

export const AuthButton = observer((props: IAuthButtonProps) => {
  const firstProofLoading = useRef<boolean>(true);
  const store = useContext(AppStoreContext);
  const [authorized, setAuthorized] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const queryClient = useQueryClient();

  const recreateProofPayload = useCallback(async () => {
    if (firstProofLoading.current) {
      tonConnectUI.setConnectRequestParameters({ state: 'loading' });
      firstProofLoading.current = false;
    }

    const payload = await TonProofDemoApi.generatePayload();

    if (payload) {
      tonConnectUI.setConnectRequestParameters({
        state: 'ready',
        value: payload
      });
    } else {
      tonConnectUI.setConnectRequestParameters(null);
    }
  }, [tonConnectUI, firstProofLoading]);

  const {
    data: currentUser,
    isFetched,
    refetch,
    isSuccess
  } = useQuery({
    queryKey: ['currentUser'],
    queryFn: () => fetchCurrentUser(),
    refetchOnWindowFocus: false,
    retry: false
  });

  useEffect(() => {
    if (isSuccess) {
      store.setCurrentUser(currentUser);
    }
  }, [store, isSuccess, currentUser]);

  if (firstProofLoading.current) {
    recreateProofPayload();
  }

  useEffect(
    () =>
      tonConnectUI.onStatusChange(async (w) => {
        store.setSwapButtonLoading(false);
        if (!w) {
          TonProofDemoApi.reset();
          setAuthorized(false);
          return;
        }

        if (w.connectItems?.tonProof && 'proof' in w.connectItems.tonProof) {
          await TonProofDemoApi.checkProof(
            w.connectItems.tonProof.proof,
            w.account
          );
        }

        if (!TonProofDemoApi.accessToken) {
          tonConnectUI.disconnect();
          setAuthorized(false);
          queryClient.removeQueries({ queryKey: ['currentUser'] });
          return;
        }
        setAuthorized(true);
        store.setWalletConnected(true);
        refetch();
      }),
    [tonConnectUI, queryClient, refetch]
  );

  const authorize = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      if (authorized) {
        props.onClick(e);
        return;
      }
      tonConnectUI.openModal();
    },
    [tonConnectUI, authorized, props]
  );

  if (!isFetched) {
    return null;
  }

  return (
    <Box sx={{ textAlign: 'right' }}>
      <AuthButtonContent
        authorizeCallback={authorize}
        isActive={props.isActive}
      />
    </Box>
  );
});
