import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { observer } from 'mobx-react';
import { useCallback, useContext, useState } from 'react';
import { avatars } from '../../assets/images';
import chevron from '../../assets/images/chevron.svg';
import { AppStoreContext } from '../../store';
import googleAnalytics from '../../utils/bubblesGa';
import CopyButton from '../CopyButton/CopyButton';

type AvatarKey = keyof typeof avatars;

const AvatarMenu = observer(() => {
  const store = useContext(AppStoreContext);
  const tonData: string = useTonAddress(true);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [tonConnectUI] = useTonConnectUI();

  const signOut = useCallback(() => {
    setAnchorEl(null);
    tonConnectUI.disconnect();
    store.setCurrentUser(null);
    store.setWalletConnected(false);
    googleAnalytics.event('sign_out', {});
  }, [setAnchorEl, tonConnectUI, store]);

  const handleOpenUserInfo = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserInfo = () => {
    setAnchorEl(null);
  };

  const avatarName = `avatar${store.currentUser?.avatarId}` as AvatarKey;

  if (!store.currentUser) return null;

  return (
    <Box className="avatar-menu">
      <Box className="avatar-wrapper" onClick={handleOpenUserInfo}>
        <Avatar
          alt="A"
          className={`user-avatar ${Boolean(anchorEl) ? 'active' : ''}`}
          src={avatars[avatarName]}
        />
        <img
          className={`user-avatar-icon ${Boolean(anchorEl) ? 'active' : ''}`}
          width={14}
          height={10}
          src={chevron}
          alt="chevron"
        />
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        className="earn-popover"
        onClose={handleCloseUserInfo}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: '12px !important',
              padding: '16px 16px',
              width: '355px',
              marginTop: '8px',
              boxShadow: '0px 0px 12px 0px #E5E8E8CC'
            }
          }
        }}>
        <Box
          mb={2}
          sx={{
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
          <CloseIcon
            onClick={handleCloseUserInfo}
            sx={{
              width: '28px',
              height: '28px'
            }}
          />
        </Box>
        <Box className="avatar-menu-wrapper" mb={2} mt={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: { xs: 'block', md: 'none' } }} mr={2}>
              <Avatar
                alt="A"
                className="user-avatar"
                src={avatars[avatarName]}
                onClick={handleOpenUserInfo}
              />
            </Box>
            <Box className="user--wallet">
              <div className='user--wallet--address'>
                {tonData.substr(0, 5) +
                '...' +
                tonData.substr(tonData.length - 5)}
                <CopyButton value={tonData} />
              </div>
              <a
                href={`https://tonscan.org/address/${tonData}`}
                className="tonscan-link"
                target="_blank"
                rel="noreferrer">
                View in TONScan
              </a>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <LogoutIcon className="logout-icon" onClick={signOut} />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
});

export default AvatarMenu;
